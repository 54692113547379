.list-circle,
.list-angle-right,
.list-arrow,
.list-check,
.list-checked,
.list-icon-box,
.list-chevron-circle,
.list-hand-point,
.list-cup,
.list-pen,
.list-heart,
.list-star {
    margin: 0 0 25px 0;
    padding: 0;
    list-style: none;
}
.list-circle li,
.list-angle-right li,
.list-arrow li,
.list-check li,
.list-checked li,
.list-icon-box li,
.list-chevron-circle li,
.list-hand-point li,
.list-cup li,
.list-pen li,
.list-heart li,
.list-star li {
    padding: 5px 5px 5px 30px;
    position: relative;
}
.right.list-circle li,
.right.list-angle-right li,
.right.list-arrow li,
.right.list-check li,
.right.list-checked li,
.right.list-icon-box li,
.right.list-chevron-circle li,
.right.list-hand-point li,
.right.list-cup li,
.right.list-pen li,
.right.list-heart li,
.right.list-star li {
    padding: 5px 30px 5px 5px;
    position: relative;
}
.list-circle li:before,
.list-angle-right li:before,
.list-arrow li:before,
.list-check li:before,
.list-checked li:before,
.list-icon-box li:before,
.list-chevron-circle li:before,
.list-hand-point li:before,
.list-cup li:before,
.list-pen li:before,
.list-heart li:before,
.list-star li:before {
    font-family: "FontAwesome";
    position: absolute;
    left: 0;
    top: 5px;
    display: block;
    font-size: 15px;
    color: #777;
}
.right.list-circle li:before,
.right.list-angle-right li:before,
.right.list-arrow li:before,
.right.list-check li:before,
.right.list-checked li:before,
.right.list-icon-box li:before,
.right.list-chevron-circle li:before,
.right.list-hand-point li:before,
.right.list-cup li:before,
.right.list-pen li:before,
.right.list-heart li:before,
.right.list-star li:before {
    font-family: "FontAwesome";
    position: absolute;
    left: auto;
    right: 0;
    top: 5px;
    display: block;
    font-size: 15px;
    color: rgb(119, 119, 119);
}
ul[class*="list-"]{
	font-size:15px;
}
.list-circle li:before {
    font-size: 16px;
}
.list-circle li:before {
    content: "\f00c";
}
.list-angle-right li:before {
    content: "\e628";
}
.list-arrow li:before {
    content: "\e661";
}
.list-check.right li:before,
.list-check li:before {
    content: "\f00c";
	color: var(--primary);
}
.list-checked li:before {
    content: "\e64d";
	font-family: 'themify';
	font-size: 15px;
}
.list-icon-box li:before {
    content: "\e6e8";
}
.list-chevron-circle li:before {
    content: "\e65d";
}
.list-hand-point li:before {
    content: "\e71b";
}
.list-star li:before {
    content: "\e60a";
}
.list-cup li:before {
    content: "\e641";
}
.list-pen li:before {
    content: "\e61c";
}
.list-heart li:before {
    content: "\e634";
}
/*listing before icon color css*/
.primary li:before {
    color: #EFBB20;
}
.secondry li:before {
    color: #77C04B;
}
.black li:before {
    color: #000;
}
.white li:before {
    color: #fff;
}
.orange li:before {
    color: #FF6600;
}
.green li:before {
    color: #00CC00;
}
.red li:before {
    color: #FF3737;
}
.no-margin {
    margin: 0 0 0 !important;
    list-style: none;
}
/*listing before numbers*/
.list-num-count {
    counter-reset: li;
    padding-left: 0;
}
.list-num-count > li {
    position: relative;
    margin: 0 0 6px 30px;
    padding: 4px 8px;
    list-style: none;
}
.list-num-count > li:before {
    content: counter(li, decimal);
    counter-increment: li;
    position: absolute;
    top: 0;
    left: -28px;
    width: 28px;
    height: 28px;
    padding: 5px;
    color: #fff;
    background: #EFBB20;
    font-weight: bold;
    text-align: center;
    font-size: 12px;
    -webkit-border-radius: 100px;
    border-radius: 100px;
}
.list-num-count.lower-alpha > li:before {
    content: counter(li, lower-alpha);
}
.list-num-count.upper-alpha > li:before {
    content: counter(li, upper-alpha);
}
.list-num-count.upper-roman > li:before {
    content: counter(li, upper-roman);
}
.list-num-count.no-round > li:before {
    background-color: rgba(0, 0, 0, 0);
    color: #333333;
    font-size: 15px;
    font-weight: normal;
    height: auto;
    left: -30px;
    margin: 0;
    width: auto;
}
.list-circle li{
	padding: 20px 30px 20px 60px;
    line-height: 34px;
    font-size: 18px;
    /* font-weight: 600; */
    background: #fff;
    margin-bottom: 20px;
    box-shadow: 0 0 30px 0 rgba(0,10,105,0.03);
    border-radius: 4px;
    margin-left: 25px;
}
.list-circle li:before {
    
    width: 60px;
    height: 60px;
    background: var(--primary);
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    color: #fff;
    left: -25px;
    top: 20px;
    border: 5px solid #eef2f8;
	transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
	
}
.list-circle li:hover:before {
	color:var(--primary);
    border-color: var(--primary);
	background: #fff;
   
}








