/*
======================================
  * CSS TABLE CONTENT *  
======================================
1. HEADER
2. HEADER TOPBAR
3. HEADER LOGO
4. MENU BAR
5. MEGA MENU
6. HEADER TRANSPARENT
7. MENU ICON
8. PAGE BANNER
9. BLOG
10. TESTIMONIALS
11. COUNTERUP
12. FOOTER
13. INNER CONTENT CSS
======================================
  * END TABLE CONTENT *  
======================================
*/

/*=================================
	1. HEADER
=================================*/
.bg-fix {
	background-attachment: fixed;
	background-size: cover;
}

.parallax {
	background-size: cover;
}

.sticky-top {
	top: 100px;
}

.header {
	position: relative;
	/* z-index: 99999; */
	z-index: 2;

}

.header ul,
.header ol {
	margin-bottom: 0;
}

.header .container-fluid {
	padding-left: 30px;
	padding-right: 30px;
}

/*without top bar*/
.secondary-menu {
	float: right;
	padding: 23px 0;
	position: relative;
	z-index: 9;
}

.secondary-menu .btn-link {
	font-size: 15px;
	padding: 0;
}

.secondary-menu .btn-link:hover {
	color: var(--primary);
}

.secondary-menu .secondary-inner {
	display: inline-block;
}

.text-underline {
	text-decoration: underline;
}

.ml-18 {
	margin-left: 18%;
}

.nav-search-bar {
	background-color: rgba(255, 255, 255, 0.95);
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	display: none;
	overflow: hidden;
	padding: 0 15px;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
}

.nav-search-bar.show {
	display: block;
}

.nav-search-bar.show form {
	transition: all 0.5s ease 0.5s;
	-moz-transition: all 0.5s ease 0.5s;
	-webkit-transition: all 0.5s ease 0.5s;
	opacity: 1;
}

.nav-search-bar form {
	width: 100%;
	max-width: 700px;
	margin: auto;
	position: relative;
	top: 50%;
	transition: all 0.5s;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transform: translate(0px, -50%);
	-moz-transform: translate(0px, -50%);
	-o-transform: translate(0px, -50%);
	-webkit-transform: translate(0px, -50%);
	opacity: 0;
}

.nav-search-bar .form-control {
	padding: 15px 60px 15px 15px;
	width: 100%;
	height: 70px;
	border: none;
	background: none;
	color: #000;
	font-size: 20px;
	border-bottom: 2px solid var(--primary);
}

.nav-search-bar .form-control::-moz-placeholder {
	color: #000;
}

.nav-search-bar .form-control:-moz-placeholder {
	color: #000;
}

.nav-search-bar .form-control:-ms-input-placeholder {
	color: #000;
}

.nav-search-bar .form-control::-webkit-input-placeholder {
	color: #000;
}

.nav-search-bar form span {
	position: absolute;
	right: 15px;
	top: 50%;
	margin: -15px 0;
	height: 25px;
	font-size: 20px;
	cursor: pointer;
	color: #000;
}

.nav-search-bar>span {
	position: absolute;
	right: 15px;
	top: 15px;
	height: 25px;
	font-size: 20px;
	cursor: pointer;
	color: #000;
}

.header-lang-bx {
	border: 0;
}

.header-lang-bx .dropdown-menu {
	top: 10px !important;
	right: 0;
	left: auto !important;
}

.header-lang-bx ul li {
	margin: 0 !important;
}

.header-lang-bx .flag:after {
	content: "";
	width: 20px;
	height: 12px;
	display: inline-block;
	background-size: cover;
	background-position: center;
	margin-top: 0px;
	margin-right: 2px;
}

.header-lang-bx .flag.flag-uk:after {
	background-image: url(../images/flag/united-kingdom.svg);
}

.header-lang-bx .flag.flag-us:after {
	background-image: url(../images/flag/united-states-of-america.svg);
}

@media only screen and (max-width: 767px) {
	.secondary-menu {
		margin-right: 5px;
	}

}

@media only screen and (max-width: 480px) {
	.secondary-menu {
		margin: 0 1px 0 0;
		text-align: right;
	}

	.portfolio-box.style-3 {
		box-shadow: 20px -11px 28px 14px rgba(194, 194, 194, 0.7);
		-webkit-box-shadow: 20px -11px 28px 14px rgba(194, 194, 194, 0.7);
		-moz-box-shadow: 20px -11px 28px 14px rgba(194, 194, 194, 0.7);
	}
}


/*=================================
	2. HEADER TOPBAR
=================================*/
.top-bar {
	background-color: #ffffff;
	border-bottom: 1px solid rgba(0, 0, 0, 0.08);
	color: #000;
	padding: 11px 0;
}

.top-bar .tobbar-inner {
	justify-content: space-between;
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
}

.topbar-left {
	float: left;
}

.topbar-right {
	float: right;
}

.topbar-center,
.topbar-left,
.topbar-right {
	padding-left: 15px;
	padding-right: 15px;
	width: auto;
}

.topbar-left ul li,
.topbar-right ul li {
	display: inline-block;
	position: relative;
}

.topbar-left ul li a,
.topbar-right ul li a {
	color: #000;
	font-size: 15px;
}

.topbar-left ul,
.topbar-right ul {
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 14px;
}

.topbar-left ul li {
	padding-right: 10px;
	margin-right: 10px;
}

.topbar-right ul li {
	padding-left: 10px;
	margin-left: 10px;
}

.topbar-left ul li i {
	margin-right: 5px;
}

.topbar-left ul li:after,
.topbar-right ul li:after {
	position: absolute;
	width: 1px;
	height: 20px;
	background-color: #000;
	right: 0;
	content: "";
	top: 2px;
	opacity: 0.1;
}

.topbar-right ul li:after {
	right: auto;
	left: 0;
}

.topbar-right ul li:first-child:after,
.topbar-left ul li:last-child:after {
	content: none;
}

.header-lang-bx .btn:focus,
.header-lang-bx .btn:hover,
.header-lang-bx .btn {
	border: 0 !important;
}

.header-lang-bx .btn {
	padding: 0 10px 0px 0 !important;
	height: 18px;
	color: #000;
	font-size: 14px;
	font-weight: 400;
	text-transform: capitalize;
}

.header-lang-bx .btn:hover {
	color: #000;
}

.header-lang-bx.bootstrap-select.btn-group .dropdown-toggle .caret {
	right: 0;
	top: 5px;
}

.header-lang-bx .btn .fa-caret-down:before {
	content: "\f107";
}

@media only screen and (max-width: 991px) {
	.topbar-right {
		padding-left: 0;
		padding-right: 15px;
	}

	.topbar-left {
		padding-left: 15px;
		padding-right: 0;
	}
}

@media only screen and (max-width: 767px) {
	.top-bar [class*="col-"] {
		width: 100%;
		text-align: right;
		padding: 10px 15px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	}

	.top-bar [class*="col-"]:last-child {
		border-bottom: none;
	}

	.top-bar {
		padding: 5px 0;
	}

	.topbar-center,
	.topbar-left,
	.topbar-right {
		display: black;
		width: 100%;
		text-align: center;
		padding: 3px 15px;
		padding-left: 15px;
		padding-right: 15px;
	}

	.topbar-left ul li {
		padding: 0 5px;
	}
}


/*=================================
	3. HEADER LOGO
=================================*/
.menu-logo {
	display: table;
	float: left;
	vertical-align: middle;
	padding: 0;
	color: #EFBB20;
	margin-top: 0;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 0;
	width: 180px;
	height: 69px;
	position: relative;
	z-index: 9;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
}

.menu-logo>a {
	display: table-cell;
	vertical-align: middle;
}

.menu-logo span {
	font-size: 20px;
	color: #EFBB20;
	letter-spacing: 20px;
}

.sticky-header.active .menu-logo {
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
}

.sticky-header.active .logo-change .logo1,
.logo-change .logo2 {
	display: none;
}

.sticky-header.active .logo-change .logo2 {
	display: block;
}

@media only screen and (max-width: 767px) {

	.menu-logo,
	.sticky-header.active .menu-logo {
		width: 100px;
		max-width: 100px;
		height: 50px;
	}

	.menu-logo img {
		max-width: 200px;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.sticky-header.active .menu-logo a img {
		vertical-align: sub;
	}

	.header .navbar-toggler,
	.header .sticky-header.active .navbar-toggler {
		margin: 18px 0 14px 15px;
	}

	.header .secondary-menu,
	.header .sticky-header.active .secondary-menu {
		padding: 13px 0;
	}
}


/*=================================
	4. MENU BAR
=================================*/
.menu-bar {
	background: #FFFFFF;
	width: 100%;
	position: relative;
}

.navbar-toggler {
	border: 0 solid #efbb20;
	font-size: 16px;
	line-height: 24px;
	margin: 28px 0 30px 25px;
	padding: 0;
	float: right;
}

.navbar-toggler span {
	background: #000;
}

.sticky-header.active .navbar-toggler {
	margin: 28px 0 30px 25px;
}

.menu-links {
	position: relative;
	padding: 0;
}

.menu-links .nav {
	float: right;
}

.menu-links .nav i {
	font-size: 9px;
	margin-left: 3px;
	margin-top: -3px;
	vertical-align: middle;
}

.menu-links .nav>li {
	margin: 0px;
	font-weight: 400;
	text-transform: uppercase;
	position: relative;
}

.menu-links .nav>li>a {
	border-radius: 0px;
	color: #000;
	font-size: 16px;
	padding: 23px 12px;
	cursor: pointer;
	font-weight: 600;
	display: inline-block;
	position: relative;
}

.menu-links .nav>li>a:hover {
	background-color: transparent;
	color: #efbb20;
}

.menu-links .nav>li>a:active,
.menu-links .nav>li>a:focus {
	background-color: transparent;
}

.menu-links .nav>li.active>a,
.menu-links .nav>li.current-menu-item>a {
	background-color: transparent;
	color: #EFBB20;
}

.menu-links .nav>li:hover>a {
	color: #EFBB20;
}

.menu-links .nav>li:hover>a:after {
	content: "";
	width: 10px;
	height: 10px;
	background: #fff;
	top: 95%;
	position: absolute;
	left: 30px;
	transform: rotate(45deg);
	z-index: 11;
}

.menu-links .nav>li.no-drop:hover>a:after {
	content: none;
}

.menu-links .nav>li.no-sub:hover>a:after {
	content: none;
}

.menu-links .nav>li:hover>.sub-menu,
.menu-links .nav>li:hover>.mega-menu {
	opacity: 1;
	visibility: visible;
	margin-top: 0;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.menu-links .nav>li>.sub-menu,
.menu-links .nav>li>.mega-menu {
	box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
}

.menu-links .nav>li .sub-menu {
	background-color: #ffffff;
	display: block;
	left: 0;
	list-style: none;
	opacity: 0;
	padding: 15px 0;
	position: absolute;
	visibility: hidden;
	width: 220px;
	z-index: 10;
	border-radius: 0;
	top: 100%;
}

.menu-links .nav>li .sub-menu li {
	position: relative;
}

.menu-links .nav>li .sub-menu li a {
	color: #9091ab;
	display: block;
	font-size: 15px;
	padding: 8px 25px;
	text-transform: capitalize;
	transition: all 0.15s linear;
	-webkit-transition: all 0.15s linear;
	-moz-transition: all 0.15s linear;
	-o-transition: all 0.15s linear;
	font-weight: 400;
	position: relative;
}

.menu-links .nav>li .mega-menu li a span,
.menu-links .nav>li .sub-menu li a span {
	position: relative;
}

.menu-links .nav>li .mega-menu li a span:after,
.menu-links .nav>li .sub-menu li a span:after {
	content: "";
	position: absolute;
	height: 1px;
	width: 0;
	background: var(--primary);
	bottom: 0;
	right: 0;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	-moz-transform-origin: right;
	-webkit-transform-origin: right;
	-ms-transform-origin: right;
	-o-transform-origin: right;
	transform-origin: right;
}

.menu-links .nav>li .mega-menu li:hover>a span:after,
.menu-links .nav>li .sub-menu li:hover>a span:after {
	width: 100%;
	-moz-transform-origin: left;
	-webkit-transform-origin: left;
	-ms-transform-origin: left;
	-o-transform-origin: left;
	transform-origin: left;
	left: 0;
	right: auto;
}

.menu-links .nav>li .sub-menu li:hover a {
	text-decoration: none;
}

.menu-links .nav>li .sub-menu li:hover>a {
	color: var(--primary);
}

.menu-links .nav>li .sub-menu li:last-child {
	border-bottom: 0px;
}

.menu-links .nav>li .sub-menu li>.sub-menu.left,
.menu-links .nav>li .sub-menu li:hover .sub-menu.left {
	left: auto;
	right: 220px;
}

.menu-links .nav>li>.sub-menu.left {
	left: auto;
	right: 0;
}

.menu-links .nav>li .sub-menu li .fa {
	color: inherit;
	display: block;
	float: right;
	font-size: 15px;
	position: absolute;
	right: 25px;
	top: 12px;
	opacity: 1;
}

.menu-links .nav>li .sub-menu li .fa.fa-nav {
	color: inherit;
	display: inline-block;
	float: none;
	font-size: 13px;
	margin-right: 5px;
	opacity: 1;
	position: unset;
	right: 10px;
	top: 12px;
}

.menu-links .nav>li .sub-menu li>.sub-menu {
	left: 220px;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.menu-links .nav>li .sub-menu li:hover>.sub-menu {
	left: 220px;
	margin: 0px;
	opacity: 1;
	top: -1px;
	visibility: visible;
}

.menu-links .nav>li .sub-menu li:hover>.sub-menu:before {
	background-color: transparent;
	bottom: 0px;
	content: '';
	display: block;
	height: 100%;
	left: -6px;
	position: absolute;
	top: 0px;
	width: 6px;
}

.menu-links .menu-close {
	height: 40px;
	color: #fff;
	background-color: var(--primary);
	line-height: 44px;
	text-align: center;
	width: 40px;
	position: absolute;
	right: 0;
	top: 0;
	display: none;
	z-index: 9999;
}

@media only screen and (max-width: 991px) {
	.menu-links .menu-close {
		display: block;
	}
}


/*=================================
	5. MEGA MENU
=================================*/
.menu-links .nav>li.has-mega-menu {
	position: inherit;
}

.menu-links .nav>li .mega-menu {
	background-color: #ffffff;
	display: table;
	left: 0px;
	list-style: none;
	opacity: 0;
	position: absolute;
	right: 0px;
	visibility: hidden;
	width: 100%;
	margin-top: 20px;
	z-index: 9;
	padding: 0;
}

.menu-links .nav>li .mega-menu>li {
	display: table-cell;
	padding: 30px 0 25px;
	position: relative;
	vertical-align: top;
	width: 25%;
}

.menu-links .nav>li .mega-menu>li:after {
	content: "";
	background-color: rgba(0, 0, 0, 0.02);
	position: absolute;
	right: 0px;
	top: 0px;
	display: block;
	width: 1px;
	height: 100%;
}

.menu-links .nav>li .mega-menu>li:last-child:after {
	display: none;
}

.menu-links .nav>li .mega-menu>li>a {
	color: #000;
	display: block;
	padding: 0 25px;
	font-size: 16px;
	text-transform: capitalize;
	font-weight: 500;
	border-bottom: 1px solid rgba(0, 0, 0, 0.03);
	margin-bottom: 20px;
	padding-bottom: 15px;
}

.menu-links .nav>li .mega-menu>li ul {
	list-style: none;
	margin: 10px 0px 0px 0px;
	padding: 0px;
	width: 100%;
}

.menu-links .nav>li .mega-menu>li ul a {
	color: #9091ab;
	display: block;
	font-size: 15px;
	text-transform: capitalize;
	line-height: 34px;
	padding: 0 25px;
}

.menu-links .nav>li .mega-menu>li ul a:hover {
	color: #EFBB20;
}

.menu-links .nav .mega-menu a i {
	font-size: 14px;
	margin-right: 5px;
	text-align: center;
	width: 15px;
}

.menu-links .nav>li.menu-item-has-children:before {
	content: "\f078";
	display: block;
	font-family: "FontAwesome";
	right: 4px;
	position: absolute;
	top: 50%;
	color: #999;
	margin-top: -8px;
	font-size: 8px;
}

/* Menu */
.menu-links .nav>li.add-mega-menu .mega-menu,
.has-mega-menu.add-mega-menu .mega-menu {
	display: block;
	padding: 20px;
	width: 1170px;
	max-width: 1170px;
	margin: auto;
}

.menu-links .nav>li .add-menu {
	display: flex;
	width: 420px;
	left: -100px;
}

.add-menu-left {
	width: 100%;
	padding-left: 25px;
	border-bottom: 0 !important;
}

.add-menu-left ul {
	padding: 0;
}

.add-menu-right {
	min-width: 240px;
	width: 240px;
	padding: 10px 20px;
}

.menu-links .nav>li.has-mega-menu.demos .mega-menu {
	left: auto;
	right: auto;
	max-width: 600px;
	z-index: 9;
}

.menu-links .nav .add-menu-left ul li {
	list-style: none;
}

.menu-links .nav .add-menu-left ul li a {
	padding: 8px 0px;
}

.menu-links .nav .add-menu-left ul li a:hover {
	background-color: rgba(0, 0, 0, 0);
}

.menu-adv-title {
	font-size: 16px;
	text-transform: capitalize;
	margin-top: 5px;
	margin-bottom: 15px;
	padding-bottom: 10px;
	position: relative;
}

.menu-adv-title:after {
	content: "";
	width: 25px;
	height: 2px;
	display: block;
	background: #000;
	position: absolute;
	bottom: 0;
	left: 0;
}

.menu-links .menu-logo,
.menu-links .nav-social-link {
	display: none;
}

.header .menu-links .nav>li.dashboard a .icon {
	padding: 0;
	font-size: 18px;
	margin-right: 15px;
	color: var(--primary);
	text-shadow: -4px 10px 15px rgba(247, 166, 0);
	margin-left: 0;
	float: none;
}

/* Header Extra Nav */
.secondary-inner ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.secondary-inner ul li {
	display: inline-block;
	position: relative;
	margin-left: 20px;
}

.secondary-inner ul li a {
	color: #000;
}

.secondary-inner i {
	margin: 0;

}

.search-btn:after {
	left: 0;
	right: auto;
	top: 5px;
}

/* Nav Badge */
.shop-cart.navbar-right {
	margin-right: 0;
	float: none;
	display: inline-block;
}

.shop-cart .dropdown-menu.cart-list {
	box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);
	top: 50px;
	border: 0;
}

.shop-cart .badge {
	font-size: 9px;
	height: 15px;
	line-height: 15px;
	min-width: 15px;
	padding: 0;
	position: absolute;
	right: -5px;
	text-align: center;
	top: -5px;
}

.shop-cart li {
	padding: 10px 20px;
	border-bottom: 1px dashed rgba(0, 0, 0, 0.1);

}

.shop-cart li:last-child {
	border-bottom: 0;
}

.shop-cart .media-left a {
	display: block;
	width: 60px;
}

.shop-cart .site-button-link {
	position: relative;
}

.cart-list.dropdown-menu .title {
	color: #999;
	margin-bottom: 0;
}

.cart-list.dropdown-menu {
	width: 260px;
	margin-top: 23px;
	right: 0;
	left: auto;
	border: 0;
}

.cart-list.dropdown-menu li {
	width: 100%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	padding: 15px 20px;
	margin: 0;
}

.sticky-header.active .cart-list.dropdown-menu {
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
}

.cart-list .media-object {
	width: 50px;
}

.cart-item .media-body span {
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	margin-right: 10px;
}

.cart-item .media-body {
	position: relative;
	padding-left: 15px;
}

.item-close {
	color: #e86161;
	cursor: pointer;
}

.menu-links .navbar-footer .copyright-text {
	display: none;
	font-size: 14px;
}

@media only screen and (max-width: 767px) {
	.shop-cart .dropdown-menu.cart-list {
		left: auto;
		right: 0;
	}

	.header .container-fluid {
		padding-left: 15px;
		padding-right: 15px;
	}

	.cart-list.dropdown-menu {
		margin-top: 15px;
	}
}

@media only screen and (max-width: 991px) {

	[class*="ovbl-"] .counter-style-1 .counter-text,
	.header-transparent .menu-links .nav>li:hover>a,
	.header-transparent .menu-links .nav>li.active>a {
		color: #000;
	}

	.hide {
		visibility: hidden;
	}

	.add-menu-left ul {
		display: block !important;
	}

	.menu-links .menu-logo,
	.menu-links .nav-social-link {
		display: block;
	}

	.menu-links .nav-social-link {
		margin-top: 15px;
		text-align: center;
		width: 100%;
		padding: 10px 0;
		background: #fff;
	}

	.menu-links .nav-social-link li {
		display: inline-block;
		padding: 0 3px;
	}

	.menu-links .nav-social-link a {
		padding: 0;
		height: 30px;
		width: 30px;
		line-height: 30px;
	}

	.menu-links .nav>li.has-mega-menu.demos .mega-menu {
		max-width: 100%;
	}

	.menu-links .nav>li .mega-menu {
		border: 0;
	}

	.menu-links .nav>li .mega-menu>li {
		padding: 10px 0;
	}

	.menu-links .nav>li .sub-menu,
	.menu-links .nav>li .mega-menu {
		border-radius: 0;
		border-width: 1px 0 1px 0;
	}

	.menu-links .nav i {
		margin-top: 6px;
		float: right;
	}

	.menu-links {
		clear: both;
		margin: 0 -15px;
		border-bottom: 1px solid #E9E9E9;
	}

	.menu-links .nav {
		float: none;
		background: #fff;
		width: 70%;
		display: block;
		margin-bottom: auto;
	}

	.menu-links .nav li {
		float: none;
		display: block;
		width: 100%;
	}

	.menu-links .nav>li .sub-menu>li,
	.menu-links .nav>li .mega-menu>li {
		float: none;
		display: block;
		width: auto;
	}

	.menu-links .nav>li>a {
		padding: 10px 15px;
		border-top: 1px dashed #E9E9E9;
		display: block;
	}

	.menu-links .nav>li>a:hover,
	.menu-links .nav>li>a:active,
	.menu-links .nav>li>a:focus {
		/* background-color: #f0f0f0; */
		text-decoration: none;
		color: #000 !important;
	}

	.menu-links .nav>li .mega-menu>li:after {
		display: none;
	}

	.menu-links .nav>li ul,
	.menu-links .nav>li .sub-menu,
	.menu-links .nav>li .mega-menu {
		display: none;
		position: static;
		visibility: visible;
		width: auto;
		background: transparent;
	}

	.menu-links .nav>li ul.mega-menu ul {
		display: none;
	}

	.menu-links .nav>li:hover>ul,
	.menu-links .nav>li:hover .sub-menu,
	.menu-links .nav>li:hover .mega-menu,
	.menu-links .nav>li .sub-menu li>.sub-menu {
		opacity: 1;
		visibility: visible;
		display: block;
		margin: 0;
	}

	.menu-links .nav>li ul.mega-menu li:hover ul {
		display: block;
	}

	.side-nav .nav.navbar-nav li a i.fa-chevron-down:before,
	.nav.navbar-nav li a i.fa-chevron-down:before {
		content: "\f078";
	}

	.side-nav .nav.navbar-nav li.open a i.fa-chevron-down:before,
	.nav.navbar-nav li.open a i.fa-chevron-down:before {
		content: "\f054";
	}

	.menu-links .nav>li .sub-menu li i.fa-angle-right:before {
		content: "\f078";
		font-size: 10px;
		position: absolute;
		z-index: 2;
		color: #000;
		right: 20px;
		top: -5px;
	}

	.menu-links .nav>li .sub-menu li.open i.fa-angle-right:before {
		content: "\f054";
	}

	.menu-links .nav>li .sub-menu .sub-menu,
	.menu-links .nav>li:hover .sub-menu .sub-menu,
	.menu-links .nav>li:hover .sub-menu,
	.menu-links .nav>li:hover .mega-menu {
		display: none;
		opacity: 1;
		margin-top: 0;
	}

	.menu-links .nav li .sub-menu .sub-menu {
		display: none;
		opacity: 1;
		margin-top: 0;
	}

	.menu-links .nav>li.open>.sub-menu .sub-menu {
		display: none;
	}

	.menu-links .nav>li.open>.sub-menu li.open .sub-menu,
	.menu-links .nav>li.open>.mega-menu,
	.menu-links .nav>li.open>.sub-menu,
	.menu-links .nav>li ul.mega-menu ul {
		display: block;
		opacity: 1;
		margin-top: 0;
		box-shadow: none;
	}

	.menu-links .nav>li:hover>a:after {
		content: none;
	}

	.menu-links .nav>li .sub-menu li .fa {
		top: 50%;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		margin: 0;
		right: -1px;
		color: #000;
	}

	.menu-links .nav>li .mega-menu>li {
		padding: 0;
	}

	.menu-links .nav>li .mega-menu>li>a {
		display: none;
	}

	.menu-links .nav .mega-menu a i {
		display: inline-block;
		float: none;
		margin-top: 0;
	}

	.menu-links .nav .open>a,
	.menu-links .nav .open>a:focus,
	.menu-links .nav .open>a:hover {
		background-color: inherit;
		border-color: #e9e9e9;
	}

	.menu-links .nav>li .sub-menu li a {
		padding: 8px 15px;
	}

	.add-menu-left {
		padding-left: 0;
	}

	.add-menu-right {
		padding: 10px 15px;
	}

	.menu-links .nav>li .mega-menu>li ul a {
		padding: 2px 15px;
	}

	.menu-links .nav li ul.add-menu .add-menu-right,
	.menu-links .nav li ul.add-menu .add-menu-left .menu-adv-title {
		display: none;
	}
}

@media screen and (max-width: 991px) {
	.shop-cart .dropdown-menu.cart-list {
		left: auto;
		right: 0;
	}

	.rs-nav .menu-links.nav-dark {
		background-color: #202020;
	}

	.rs-nav .menu-links {
		position: fixed;
		width: 60px;
		left: -105%;
		height: 100vh;
		transition: all 0.5s;
		-webkit-transition: all 0.5s;
		-moz-transition: all 0.5s;
		-o-transition: all 0.5s;
		top: 0;
		background-color: #fff;
		margin: 0;
		z-index: 99999;
		overflow-y: scroll;
		flex-direction: column;
		display: flex;
		justify-content: unset !important;
		padding: 15px;
		width: 100%;
	}

	.rs-nav .menu-links li.open a {
		position: relative;
	}

	.navbar-nav {
		height: auto;
	}

	.rs-nav .menu-links.show {
		left: 0;
		transition: all 0.8s;
		-webkit-transition: all 0.8s;
		-moz-transition: all 0.8s;
		-o-transition: all 0.8s;
	}

	.rs-nav .sticky-header.active .menu-links .nav {
		height: auto;
	}

	.rs-nav .navbar-toggler.open:after {
		background-color: rgba(0, 0, 0, 0.6);
		content: "";
		height: 100%;
		left: 0;
		position: fixed;
		right: 0px;
		top: -20px;
		transform: scale(100);
		-o-transform: scale(100);
		-moz-transform: scale(100);
		-webkit-transform: scale(100);
		width: 100%;
		z-index: -1;
		transition: all 0.5s;
		transform-origin: top right;
		margin: 0 0px 0px 10px;
		box-shadow: 0 0 0 500px rgba(0, 0, 0, 0.6);
	}

	.rs-nav .menu-links .menu-logo {
		display: block;
		float: none;
		height: auto;
		max-width: 100%;
		padding: 20px 15px;
		width: 100%;
		text-align: center;
		margin-bottom: 15px;
	}

	.rs-nav .menu-links .menu-logo img {
		max-width: unset;
		width: 200px;
		vertical-align: middle;
	}

	.rs-nav .menu-links .menu-logo a {
		display: inline-block;
	}

	.rs-nav .navbar-toggler.open span {
		background: #fff;
	}

	.menu-links .navbar-footer .copyright-text {
		display: block;
	}

	.menu-links .navbar-footer {
		display: block;
		padding: 30px 0 10px;
		width: 100%;
		text-align: center;
	}

	.navbar-footer .copyright-text {
		font-size: 15px;
		margin-bottom: 0;
	}

	.menu-links .nav>li .sub-menu,
	.menu-links .nav>li .mega-menu {
		border-top: 1px dashed rgba(0, 0, 0, 0.1);
	}

	.menu-links .nav>li .mega-menu {
		padding: 15px 0;
	}

	.menu-links .nav>li .mega-menu li a span:after,
	.menu-links .nav>li .sub-menu li a span:after {
		content: none;
	}
}

.sticky-no .menu-bar {
	position: static !important;
}

.sticky-header.active .menu-bar {
	position: fixed;
	top: 0;
	left: 0;
	box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 991px) {
	.sticky-header.active .menu-links .nav>li>a {
		padding: 10px 15px;
	}
}

@media only screen and (max-width: 767px) {
	.sticky-header.active .menu-links .nav {
		height: 225px;
	}

	.sticky-header.active .menu-links .nav>li>a {
		padding: 10px 15px;
	}
}

@media only screen and (max-width: 591px) {
	.menu-links .nav {
		width: 100%;
	}
}


/*=================================
	6. HEADER TRANSPARENT
=================================*/
.header-transparent {
	position: absolute;
	width: 100%;
}

.header-transparent .menu-bar {
	background-color: rgba(0, 0, 0, 0);
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header-transparent .menu-links .nav>li>a {
	color: #fff;
}

.header-transparent .menu-links .nav>li>a,
.header-transparent .secondary-menu .btn-link,
.header-transparent .navbar-toggler {
	color: #fff;
}

.header-transparent .secondary-menu .btn-link:hover {
	color: var(--primary);
}

.header-transparent .navbar-toggler span {
	background-color: #fff;
}

.header-transparent .sticky-header.active .menu-bar {
	position: fixed;
	background-color: rgba(0, 0, 0, 0.9);
}

.header-transparent .top-bar {
	background-color: rgba(0, 0, 0, 0);
	border-bottom: 1px solid rgba(255, 255, 255, 0.08);
	color: #dfdfdf;
}

.header-transparent .search-btn:after,
.header-transparent .topbar-left ul li:after,
.header-transparent .topbar-right ul li:after {
	background-color: #fff;
}

.fullwidth .container-fluid {
	padding-left: 30px;
	padding-right: 30px;
}

.onepage .navbar {
	margin-bottom: 0;
	border: 0;
}

.margin-bottom {
	margin-bottom: 45px;
}

.header-transparent .header-lang-bx .btn {
	background-color: transparent !important;
	color: #fff;
}

.header-transparent .topbar-right .header-lang-bx ul li a {
	color: #000;
}

.header-transparent .topbar-left ul li a,
.header-transparent .topbar-right ul li a {
	color: #fff;
}

@media only screen and (max-width: 991px) {
	.header-transparent .menu-links .nav>li>a {
		color: #000;
	}

	.header-transparent .menu-links.nav-dark .nav>li>a {
		color: #fff;
	}
}

@media only screen and (max-width: 767px) {
	.fullwidth .container-fluid {
		padding-left: 15px;
		padding-right: 15px;
	}
}


/*=================================
	7. MENU ICON
=================================*/
.menuicon {
	width: 20px;
	height: 14px;
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
}

.menuicon span {
	display: block;
	position: absolute;
	height: 2px;
	width: 100%;
	border-radius: 1px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .25s ease-in-out;
	-moz-transition: .25s ease-in-out;
	-o-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
}

.menuicon span:nth-child(1) {
	top: 0px;
}

.menuicon span:nth-child(2) {
	top: 50%;
	transform: translateY(-50%)
}

.menuicon span:nth-child(3) {
	bottom: 0;
}

.menuicon i {
	position: relative;
	width: 14px;
	display: inline-block;
	height: 12px;
	margin-left: 5px;
}

.menuicon.open i {
	height: 12px;
}

.menuicon.open i span:nth-child(1),
.menuicon.open i span:nth-child(3) {
	top: 5px;
}

.header-transparent .extmenu a {
	color: #fff;
	text-transform: uppercase;
	font-weight: 600;
}

.extmenu a span {
	background: #fff;
}


/*========================
	8. PAGE BANNER
=========================*/
.page-banner {
	height: 450px;
	background-size: cover;
	background-position: center center;
	display: table;
	width: 100%;
	text-align: left;
	background-color: #f7f7f7;
}

.page-banner.bg-gray {
	background-color: #eef2f8;
}

.page-banner-sm {
	height: 250px !important;
}

.page-banner-lg {
	height: 700px;
}

.page-banner .container {
	display: table;
	height: 100%;
}

.page-banner-entry {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}

.page-banner-entry.align-m {
	vertical-align: middle;
}

.page-banner h1 {
	font-size: 50px;
	margin-bottom: 10px;
	line-height: 65px;
}

.breadcrumb-row ul li a {
	color: var(--primary);
}

.breadcrumb-row ul li a i {
	margin-right: 5px;
}

.banner-sm-title {
	color: var(--primary);
	font-size: 22px;
	text-transform: uppercase;
	font-weight: 600;
	display: block;
	margin-bottom: 5px;
}

.breadcrumb-row.text-black {
	background: rgba(0, 0, 0, 0.05);
	padding: 10px 20px;
	border: 1px solid rgba(0, 0, 0, 0.08);
	display: inline-block;
	border-radius: 6px;
}

.breadcrumb-row.text-black ul li {
	color: #333;
}

.breadcrumb-row.text-black ul li a {
	color: var(--primary);
}

@media only screen and (max-width: 767px) {
	.page-banner-entry {
		vertical-align: middle;
	}

	.page-banner {
		padding-bottom: 0;
		height: 200px;
	}

	.page-banner h1 {
		line-height: 24px;
		font-size: 20px;
		text-align: center;
	}
}


/*========================
	9. BLOG
=========================*/
.blog-post {
	position: relative;
	margin-bottom: 30px;
}

.ttr-post-title {
	margin-bottom: 5px;
}

.ttr-post-title .post-title {
	margin-top: 20px;
	margin-bottom: 5px;
}

.ttr-post-meta {
	margin-bottom: 15px;
}

.ttr-post-meta ul {
	list-style: none;
	text-transform: capitalize;
	margin: 0;
	padding: 0;
}

.ttr-post-meta ul li {
	padding: 0;
	display: inline-block;
	color: #25314a;
	font-weight: 600;
	font-size: 14px;
	text-transform: uppercase;
}

.ttr-post-meta ul li strong {
	font-weight: 500;
}

.ttr-post-meta li:after {
	content: "|";
	display: inline-block;
	font-weight: normal;
	margin-left: 5px;
	opacity: 0.5;
}

.ttr-post-meta li:last-child:after {
	display: none;
}

.ttr-post-meta a {
	color: var(--primary);
}

.ttr-post-meta i {
	margin: 0 5px;
	font-size: 15px;
}

.ttr-post-text {
	margin-bottom: 20px;
}

.ttr-post-text p:last-child {
	margin: 0;
}

@media only screen and (max-width: 1200px) {
	.ttr-post-meta .d-flex {
		display: block !important;
	}
}


/*blog post half iamge*/
.blog-md:not(.md-large) {
	box-shadow: 0 0 30px 0 rgba(0, 10, 105, 0.03);
	background: #fff;
	padding: 30px;
	border-radius: 4px;
}

.blog-md .ttr-post-media {
	width: 160px;
	border-radius: 4px;
}

.blog-md.sm-large,
.blog-md.md-large {
	display: flex;
	flex-wrap: wrap;
}

.blog-md.md-large {
	margin-bottom: 60px;
	align-items: center;
}

.blog-md.md-large .ttr-post-media {
	width: 55%;
	flex: 0 0 55%;
}

.blog-md.md-large .ttr-post-info {
	width: 45%;
	flex: 0 0 45%;
	display: flex;
	flex-direction: column;
	padding: 0 0 0 30px;
}

.blog-md.md-large .post-title {
	font-size: 40px;
	line-height: 50px;
}

.blog-md.sm-large .media-post li a,
.blog-md.md-large .media-post li a {
	vertical-align: middle;
	font-size: 15px;
	font-weight: 600;
}

.blog-md.sm-large .media-post li a {
	font-size: 14px;
}

.blog-md.md-large p {
	font-size: 17px;
	color: #585a86;
	line-height: 30px;
}

.blog-md.sm-large .media-post,
.blog-md.md-large .media-post {
	border: 0;
	padding: 0;
	margin-bottom: 10px;
}

.blog-md.md-large .ttr-post-info .post-title {
	margin-bottom: 10px;
}

.blog-md.sm-large .ttr-post-info .post-extra,
.blog-md.md-large .ttr-post-info .post-extra {
	border: 0;
}

.blog-md.sm-large {
	margin-bottom: 0;
}

.blog-md.sm-large .ttr-post-media {
	width: 45%;
	flex: 0 0 45%;
	display: flex;
	align-items: stretch;
}

.blog-md.sm-large .ttr-post-info {
	width: 55%;
	flex: 0 0 55%;
	display: flex;
	flex-direction: column;
	padding: 0 0 0 30px;
}

.blog-md.sm-large .post-title {
	font-size: 21px;
	line-height: 30px;
}

@media only screen and (max-width: 1480px) {
	.blog-md.sm-large .ttr-post-info {
		padding: 0 0 0 15px;
	}

	.blog-md:not(.md-large) {
		padding: 20px;
	}
}

.recent-news-carousel {
	padding: 30px 0 30px 100px;
	background-color: #eef2f8;
}

.recent-news-carousel .ttr-post-media {
	border-radius: 4px;
	overflow: hidden;
}

.recent-news-carousel .ttr-post-media img {
	height: 100%;
	object-fit: cover;
}

.recent-news-carousel .owl-stage {
	padding-left: 0 !important;
}

.recent-news-carousel .slick-prev,
.recent-news-carousel .slick-next {
	line-height: 50px;
	height: 50px;
	width: 50px;
	border-radius: 50px;
	background-color: var(--primary);
	color: #fff;
	margin: 5px 0 !important;
}

.recent-news-carousel .slick-prev {
	left: 30px;
	top: 50%;
	transform: translateY(-120%);
}

.recent-news-carousel .slick-list {
	margin-right: -180px;
}

.recent-news-carousel .slick-next {
	right: auto;
	left: 30px;
	top: 50%;
	transform: translateY(-10%);
}

@media only screen and (max-width: 1280px) {
	.recent-news-carousel .slick-list {
		margin-right: 0;
	}

	.recent-news-carousel {
		padding: 30px 15px;
		margin: 0 !important;
	}

	.recent-news-carousel .slick-prev,
	.recent-news-carousel .slick-next {
		display: none !important;
	}
}

.recent-news-carousel .slick-prev:before,
.recent-news-carousel .slick-next:before {
	line-height: 50px;
	opacity: 1;
}

.recent-news-carousel .owl-prev:hover,
.recent-news-carousel .owl-next:hover {
	background-color: var(--sc-primary);
	color: #000;
}

.blog-md .ttr-post-info {
	border: none;
	padding-left: 30px;
}

.blog-md .ttr-post-tags {
	border: none;
	display: inline-block;
	padding: 0;
}

.blog-md .ttr-post-info .post-title {
	margin-top: 0;
}

.blog-md,
.blog-md .ttr-post-info {
	overflow: hidden;
}

.blog-md .ttr-post-info,
.blog-md .ttr-post-media {
	display: table-cell;
	vertical-align: middle;
}

.blog-md .ttr-post-media {
	/* vertical-align: top; */
}

.blog-md .ttr-post-info>div:last-child {
	margin-bottom: 0;
}


/* Blog Share */
.blog-share {
	position: relative
}

.share-btn {
	position: absolute;
	right: 0;
	bottom: 0;
}

.share-btn ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.share-btn ul li {
	display: inline-block;
	margin-left: -40px;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
}

.share-btn ul li a.btn {
	border-radius: 3px;
	width: 40px;
	line-height: 44px;
	height: 44px;
	display: block;
	color: #000;
	background: #E6E6E6;
}

.share-btn ul li a.btn:hover {
	background: #D6D6D6;
}

.share-btn ul:hover li.share-button a.btn {
	background: #A0A0A0;
}

.share-btn ul:hover li {
	margin-left: 4px;
}

.share-details-btn ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.share-details-btn ul li {
	display: inline-block;
	margin-right: 5px;
	margin-bottom: 6px;
}


/* recent news style 2 */
.recent-news.style-2 {
	overflow: hidden;
	border-radius: 10px;
	box-shadow: 0 0 30px 0 rgba(0, 10, 105, 0.03);
	margin-bottom: 50px;
	position: relative;
}

.recent-news.style-2 .media-post li.date a {
	background: var(--primary);
	color: #fff;
	font-weight: 600;
	text-transform: uppercase;
	font-size: 14px;
	padding: 10px 30px;

}

.recent-news.style-2 .media-post li a {
	font-weight: 600;
	text-transform: uppercase;
	color: #9193c2;
	font-size: 14px;
	letter-spacing: unset;
}

.recent-news.style-2 .media-post {
	display: flex;
	align-items: center;
	border-bottom: 0;
	padding: 0;
}

.recent-news.style-2 .media-post li.date {
	padding: 0;
	position: absolute;
	top: 0;
	transform: rotate(90deg);
	transform-origin: top left;
	line-height: 14px;
}

.recent-news.style-2 .media-post li {
	padding: 0 20px 0 0px;
	position: relative;
}

.recent-news.style-2 .media-post li.admin a img {
	width: 36px;
	height: 36px;
	margin-right: 15px;
	border-radius: 36px;
}

.recent-news.style-2 .media-post li.comment a {
	display: flex;
}

.recent-news.style-2 .media-post li a i {
	font-size: 20px;
	color: var(--primary);
	margin-right: 5px;
}

.recent-news.style-2 .info-bx p {
	font-size: 16px;
	line-height: 28px;
	color: #25314a;
}

.recent-news.style-2 .info-bx {
	position: unset;
	padding: 25px;
}

.recent-news.style-2 .post-extra {
	padding: 0;
	border: 0;
	position: relative;
}

.recent-news.style-2 .post-extra .btn {
	padding: 13px 30px;
}

.aside-bx {
	box-shadow: 0 0 30px 0 rgba(0, 10, 105, 0.03);
	background: #fff;
	padding: 40px 25px;
	border-radius: 4px;
}

@media only screen and (max-width: 767px) {

	.blog-md.blog-post .ttr-pfost-media,
	.blog-md.blog-post .ttr-post-info {
		float: none;
		margin: 0 0 20px;
		width: 100%;
		display: block;
		padding-left: 0;
	}

	.blog-md.blog-post .ttr-post-info {
		margin-top: 15px;
	}

	.blog-md .ttr-post-media {
		width: 100%;
	}
}

/*Blog single*/
.blog-single {
	background: #fff;
	padding: 30px;
	margin-bottom: 30px;
	border-radius: 4px;
	box-shadow: 0 0 30px 0 rgba(0, 10, 105, 0.03);
}

.blog-single .media-post {
	padding: 0;
}

.blog-single .action-box {
	margin-bottom: 30px;
}

.blog-single .ttr-post-text ul {
	padding: 0 0 0 30px;
}

.blog-single .ttr-post-text ul li {
	padding: 5px 0;
}

.blog-single .ttr-post-meta .post-tags a {
	color: #25314a;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	margin-right: 10px;
	display: inline-block;
}

.blog-single .ttr-post-meta {
	margin-bottom: 0;
}

.blog-single .ttr-post-text {
	margin-top: 20px;
}

.blog-single .ttr-post-tags {
	margin-top: 20px;
}

.blog-single .ttr-post-media {
	border-radius: 4px;
}

/*= comment list = */
.comments-area {
	padding: 0;
}

.comments-area .comments-title {
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 600;
	line-height: 28px;
}

ol.comment-list {
	list-style: none;
	margin-bottom: 0;
	padding-left: 0;
}

ol.comment-list li.comment {
	position: relative;
	padding: 0;
}

ol.comment-list li.comment .comment-body {
	margin-bottom: 40px;
	margin-left: 80px;
	position: relative;
	background: #fff;
	box-shadow: 0 0 30px 0 rgba(0, 10, 105, 0.03);
	padding: 30px 30px;
	border-radius: 4px;
}

ol.comment-list li.comment .comment-author {
	display: block;
	margin-bottom: 0;
}

ol.comment-list li.comment .comment-author .avatar {
	position: absolute;
	top: 0;
	left: -80px;
	width: 70px;
	height: 70px;
	border-radius: 64px;
	-webkit-border-radius: 64px;
	background: #fff;
	padding: 4px;
	box-shadow: 0 0 30px 0 rgba(0, 10, 105, 0.03);
}

ol.comment-list li.comment .comment-author .fn {
	display: inline-block;
	color: #000000;
	font-size: 22px;
	font-weight: 600;
	font-style: normal;
	margin-bottom: 6px;
	line-height: 30px;
}

ol.comment-list li.comment .comment-author .says {
	display: none;
	color: #999999;
	font-weight: 600;
}

ol.comment-list li.comment .comment-meta {
	color: #8d8d8d;
	text-transform: capitalize;
	margin-bottom: 15px;
}

ol.comment-list li.comment .comment-meta a {
	color: #8d8d8d;
}

ol.comment-list li.comment .comment-meta a {
	color: var(--primary);
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 1px;
}

ol.comment-list li.comment p {
	margin: 0 0 5px;
	font-size: 16px;
	font-weight: 400;
	line-height: 28px;
	color: #25314a;
}

ol.comment-list li.comment .reply a {
	color: var(--primary);
	border: 2px solid var(--primary);
	padding: 4px 20px;
	display: inline-block;
	border-radius: 40px;
	margin-top: 10px;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 2px;
}

ol.comment-list li.comment .reply a:hover {
	background-color: var(--primary);
	color: #fff;
}

ol.comment-list li .children {
	list-style: none;
	margin-left: 80px;
	padding: 0;
}

ol.comment-list li .children li {
	padding: 0;
}

@media only screen and (max-width: 767px) {
	.comments-area .padding-30 {
		padding: 15px;
	}

	ol.comment-list li.comment .comment-body {
		margin-bottom: 30px;
		margin-left: 70px;
	}

	ol.comment-list li.comment .comment-author .avatar {
		left: -75px;
		height: 60px;
		width: 60px;
	}

	ol.comment-list li .children {
		margin-left: 20px;
	}

	ol.comment-list li.comment .reply a {
		position: static;
	}
}

@media only screen and (max-width: 480px) {
	ol.comment-list li.comment .comment-body {
		margin-left: 52px;
	}

	ol.comment-list li.comment .comment-author .avatar {
		left: -55px;
		top: 12px;
		width: 40px;
		height: 40px;
	}
}

/*= comment form = */
.comment-respond {
	padding: 30px 30px;
	background: #fff;
	box-shadow: 0 0 30px 0 rgba(0, 10, 105, 0.03);
	border-radius: 4px;
}

.comment-respond .comment-reply-title {
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 600;
}

.comments-area .comment-form {
	margin: 0 -15px;
}

.comments-area .comment-form .comment-notes {
	display: none;
}

.comments-area .comment-form p {
	width: 33.333%;
	float: left;
	padding: 0 15px;
	margin-bottom: 30px;
	position: relative;
}

.comments-area .comment-form p.form-allowed-tags {
	width: 100%;
}

ol.comment-list li.comment .comment-respond .comment-form p {
	padding: 0 15px !important;
}

.comments-area .comment-form p label {
	display: none;
	line-height: 18px;
	margin-bottom: 10px;
}

.comments-area .comment-form p input[type="text"],
.comments-area .comment-form p textarea {
	width: 100%;
	height: 42px;
	padding: 14px 22px 14px 22px;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	-webkit-border-radius: 4px;
	text-transform: capitalize;
	color: #000000;
	background: transparent;
	font-size: 14px;
}

.comments-area .comment-form p input[type="text"]:hover,
.comments-area .comment-form p textarea:hover,
.comments-area .comment-form p input[type="text"]:focus,
.comments-area .comment-form p textarea:focus {
	border-color: var(--primary);
	box-shadow: none !important;
	outline: none;
}

.comments-area .comment-form p.comment-form-comment {
	width: 100%;
	display: block;
	clear: both;
}

.comments-area .comment-form p textarea {
	height: 120px;
}

.comments-area .comment-form p.form-submit {
	clear: both;
	float: none;
	width: 100%;
	margin: 0;
}

.comments-area .comment-form p input[type="submit"] {
	background-color: #EFBB20;
	border: none;
	border-radius: 40px;
	border-style: solid;
	border-width: 0;
	color: #fff;
	display: inline-block;
	padding: 10px 20px;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 14px;
	padding: 8px 30px;
}

.comments-area .comment-form p input[type="submit"]:hover {
	background-color: #fff;
	border: 2px solid var(--primary);
	color: var(--primary);
}

.comments-area .comment-form p input[type="submit"]:focus,
.comments-area .comment-form p input[type="submit"]:active {
	background-color: var(--primary);

}

@media only screen and (max-width: 767px) {
	.comments-area .comment-form p {
		width: 100%;
		float: none;
		margin-bottom: 20px;
	}

	.comment-respond {
		padding: 20px;
	}
}

/* Blog User */
.author-profile-pic {
	width: 125px;
	height: 125px;
	border-radius: 100px;
	overflow: hidden;
	box-shadow: -2px 5px 10px 0 rgba(0, 16, 147, 0.1);
	z-index: 2;
	position: relative;
	margin: auto;
}

.author-profile-content ul li a {
	padding: 0 2px;
	background: var(--primary);
	width: 40px;
	height: 40px;
	border-radius: 4px;
	color: #fff;
	line-height: 40px;
	text-align: center;
}

.author-profile-content ul li a:hover {
	background: var(--sc-primary);
	color: #000;
}

.author-profile-content ul li a i {
	margin: 0;
}

.author-box {
	background-color: #fff;
	padding: 30px;
	border-radius: 4px;
	box-shadow: 0 0 30px 0 rgba(0, 10, 105, 0.03);
}

.author-box.blog-user .author-profile-info {
	display: flex;
}

.blog-user .author-profile-pic img {
	width: 100%;
}

.blog-user .author-profile-pic {
	width: 100px;
	min-width: 100px;
	height: 100px;
	border-radius: 4px;
	overflow: hidden;
	box-shadow: none;
	z-index: 2;
	position: relative;
	margin: 0 auto;
	border: 0;
}

.blog-user .author-profile-content {
	padding-left: 20px;
	text-align: left;
	padding-top: 0;
	width: 100%;
}

.blog-user .author-profile-content h6 {
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 5px;
}

.blog-user .author-profile-content p {
	margin-bottom: 15px;
	color: #25314a;
}

/* Gutenberg Css */
.min-container,
.blog-post.blog-single {
	max-width: 750px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px;
}

.max-container {
	max-width: 1500px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 55px;
	padding-right: 55px;
}

.blog-post.blog-single .dlab-post-info {
	padding: 0;
}

.blog-post.blog-single .dlab-post-text.text>ol,
.blog-post.blog-single .dlab-post-text.text>ul {
	padding-left: 30px;
}

.blog-post.blog-single .dlab-post-text.text>ol li,
.blog-post.blog-single .dlab-post-text.text>ul li {
	padding: 5px 5px;
	color: #2b2d2e;
}

.blog-post.blog-single .dlab-post-text.text>.wp-block-gallery {
	padding: 0;
	list-style: none;
}

.blog-post.blog-single .dlab-post-text.text>.wp-block-gallery li {
	padding: 0;
	list-style: none;
	margin: 10px;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
	margin: 0 8px 8px 0;
}

.wp-block-gallery.columns-4 .blocks-gallery-image,
.wp-block-gallery.columns-4 .blocks-gallery-item,
.wp-block-gallery.columns-3 .blocks-gallery-image,
.wp-block-gallery.columns-3 .blocks-gallery-item {
	margin-right: 8px;
}

.content-spacious-full .wp-block-gallery {
	margin-left: calc(-200px - (10px/2));
	margin-right: calc(-200px - (10px/2));
	width: calc(100% + 400px + 10px);
	max-width: initial;
}

.alignwide {
	margin-left: calc(-100px - (10px/2));
	margin-right: calc(-100px - (10px/2));
	width: calc(100% + 200px + 10px);
	max-width: initial;
}

.alignfullwide {
	margin-left: calc(50% - 50vw - (var(--content-share-ml, 0px)/2));
	width: 100vw;
	max-width: 100vw;
	box-sizing: border-box;
}

.thm-unit-test .dlab-post-text h1,
.thm-unit-test .dlab-post-text h2,
.thm-unit-test .dlab-post-text h3,
.thm-unit-test .dlab-post-text h4,
.thm-unit-test .dlab-post-text h5,
.thm-unit-test .dlab-post-text h6 {
	font-family: Roboto;
}

.dlab-post-text h1,
.thm-unit-test .dlab-post-text h1 {
	font-size: 36px;
	line-height: 45px;
	margin-bottom: 10px;
	font-weight: 700;
}

.dlab-post-text h2,
.thm-unit-test .dlab-post-text h2 {
	font-size: 32px;
	line-height: 45px;
	margin-bottom: 5px;
	font-weight: 700;
}

.dlab-post-text h3,
.thm-unit-test .dlab-post-text h3 {
	font-size: 28px;
	line-height: 35px;
	margin-bottom: 8px;
	font-weight: 700;
}

.dlab-post-text h4,
.thm-unit-test .dlab-post-text h4 {
	font-size: 22px;
	line-height: 32px;
	margin-bottom: 5px;
	font-weight: 600;
}

.dlab-post-text h5,
.thm-unit-test .dlab-post-text h5 {
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 8px;
	font-weight: 600;
}

.dlab-post-text h6,
.thm-unit-test .dlab-post-text h6 {
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 10px;
	font-weight: 600;
}

pre {
	margin: 0 0 25px;
	padding: 20px;
	color: #ddd;
	background-color: #222;
	white-space: pre;
	text-shadow: 0 1px 0 #000;
	border-radius: 5px;
	border-bottom: 1px solid #555;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4) inset, 0 0 20px rgba(0, 0, 0, 0.2) inset;
}

.blog-post.blog-single .dlab-post-text ul li,
.blog-post.blog-single .dlab-post-text ol li,
.blog-post.blog-single .dlab-post-text p {
	font-family: 'Domine', serif;
	font-size: 15px;
	line-height: 28px;
}

.blog-post.blog-single .dlab-post-text p {
	margin-bottom: 28px;
}

.blog-post.blog-single .dlab-post-text blockquote p {
	font-size: 18px;
	line-height: 30px;
	color: #2b2d2e;
	margin-bottom: 10px;
	font-family: Montserrat;
	font-weight: 600;
	text-align: left;
}

.blog-post.blog-single .dlab-post-text .wp-block-gallery {
	margin-left: calc(-190px - (10px/2));
	margin-right: calc(-190px - (10px/2));
	width: calc(100% + 380px + 10px);
	max-width: initial;
}

.blog-post.blog-single .dlab-post-text>div+p:first-letter,
.blog-post.blog-single .dlab-post-text>p:first-child:first-letter {
	color: #6d9ec4;
	font-size: 100px;
	float: left;
	margin-right: 20px;
	margin-bottom: 14px;
	margin-top: 15px;
}

.paginate-links {
	display: flex;
	align-items: center;
	margin: 20px 0;
}

.paginate-links a,
.paginate-links>span {
	margin: 0 0 0 10px;
	position: relative;
	border: 0px solid #6cc000;
	color: #000;
	display: inline-block;
	font-size: 16px;
	font-weight: 500;
	line-height: 40px;
	min-width: 40px;
	height: 40px;
	text-align: center;
	text-transform: capitalize;
	transition: all 500ms ease 0s;
	background: #E0E0E0;
	border-radius: 3px;
}

.wp-block-button__link {
	border-radius: 4px;
	font-size: 16px;
	padding: 10px 30px;
	font-weight: 700;
}

.blog-post.blog-single .dlab-post-text .has-large-font-size {
	font-size: 36px;
	line-height: 50px;
	color: #000;
}

/* Gutenberg Gallery */
.wp-block-gallery {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	padding: 0 !important;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: center;
	margin: 0 6px 6px 0;
	position: relative
}

.wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure {
	height: 100%;
	margin: 0
}

@supports ((position:-webkit-sticky) or (position:sticky)) {

	.wp-block-gallery .blocks-gallery-image figure,
	.wp-block-gallery .blocks-gallery-item figure {
		align-items: flex-end;
		display: flex;
		justify-content: flex-start
	}
}

.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img {
	display: block;
	height: auto;
	max-width: 100%;
	width: 100%
}

@supports ((position:-webkit-sticky) or (position:sticky)) {

	.wp-block-gallery .blocks-gallery-image img,
	.wp-block-gallery .blocks-gallery-item img {
		width: auto
	}
}

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
	background: linear-gradient(0deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .3) 60%, transparent);
	bottom: 0;
	color: #fff;
	font-size: 13px;
	max-height: 100%;
	overflow: auto;
	padding: 40px 10px 5px;
	position: absolute;
	text-align: center;
	width: 100%
}

.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img {
	display: inline
}

.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img {
	width: 100%;
	border-radius: 4px;
}

@supports ((position:-webkit-sticky) or (position:sticky)) {

	.wp-block-gallery.is-cropped .blocks-gallery-image a,
	.wp-block-gallery.is-cropped .blocks-gallery-image img,
	.wp-block-gallery.is-cropped .blocks-gallery-item a,
	.wp-block-gallery.is-cropped .blocks-gallery-item img {
		-o-object-fit: cover;
		flex: 1;
		height: 100%;
		object-fit: cover
	}
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
	width: calc(50% - 8px)
}

.wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
	margin-right: 0
}

.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item {
	margin-right: 0;
	width: 100%
}

@media only screen and (max-width: 1024px) {
	.alignwide {
		margin-left: 0;
		margin-right: 0;
		width: 100% !important;
		max-width: 100% !important;
	}

	.alignwide .alignleft img {
		width: 100%;
	}

	.alignwide .alignleft {
		margin: 5px 0px 25px 0;
		float: none;
	}

}

@media (min-width:600px) {

	.wp-block-gallery.columns-3 .blocks-gallery-image,
	.wp-block-gallery.columns-3 .blocks-gallery-item {
		margin-right: 6px;
		width: calc(33.33333% - 10.66667px)
	}

	.wp-block-gallery.columns-4 .blocks-gallery-image,
	.wp-block-gallery.columns-4 .blocks-gallery-item {
		margin-right: 6px;
		width: calc(25% - 12px)
	}

	.wp-block-gallery.columns-5 .blocks-gallery-image,
	.wp-block-gallery.columns-5 .blocks-gallery-item {
		margin-right: 6px;
		width: calc(20% - 12.8px)
	}

	.wp-block-gallery.columns-6 .blocks-gallery-image,
	.wp-block-gallery.columns-6 .blocks-gallery-item {
		margin-right: 6px;
		width: calc(16.66667% - 13.33333px)
	}

	.wp-block-gallery.columns-7 .blocks-gallery-image,
	.wp-block-gallery.columns-7 .blocks-gallery-item {
		margin-right: 6px;
		width: calc(14.28571% - 13.71429px)
	}

	.wp-block-gallery.columns-8 .blocks-gallery-image,
	.wp-block-gallery.columns-8 .blocks-gallery-item {
		margin-right: 6px;
		width: calc(12.5% - 14px)
	}

	.wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
	.wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
	.wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
	.wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
	.wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
	.wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
	.wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
	.wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
	.wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
	.wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
	.wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
	.wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
	.wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
	.wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
	.wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
	.wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
		margin-right: 0
	}
}

.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child {
	margin-right: 0
}

.wp-block-gallery .blocks-gallery-item.has-add-item-button {
	width: 100%
}

.wp-block-image.alignfullwide img {
	border-radius: 0;
}

.wp-block-image img {
	border-radius: 10px;
}

.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
	max-width: 305px;
	width: 100%
}

.wp-block-gallery.aligncenter,
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
	display: flex
}

.aligncenter {
	text-align: center;
}

.wp-block-gallery.aligncenter .blocks-gallery-item figure {
	justify-content: center
}

/*========================
	10. TESTIMONIALS
=========================*/
.testimonial-pic {
	background: #FFF;
	width: 100px;
	height: 100px;
	position: relative;
	display: inline-block;
	border: 5px solid #FFF;
}

.testimonial-pic.radius {
	border-radius: 100%;
	-webkit-border-radius: 100%;
}

.testimonial-pic.radius img {
	width: 100%;
	height: 100;
	border-radius: 100%;
	-webkit-border-radius: 100%;
}

.testimonial-pic.shadow {
	-webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
	-moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
	box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
}

.testimonial-text {
	padding: 15px;
	position: relative;
	font-size: 15px;
	font-family: "Montserrat", sans-serif;
	font-weight: 400;
}

.testimonial-detail {
	padding: 5px;
}

.testimonial-name {
	font-family: montserrat;
	font-size: 16px;
	font-weight: 600;
	text-transform: uppercase;
}

.testimonial-position {
	font-family: montserrat;
	font-size: 12px;
	font-style: inherit;
	text-transform: uppercase;
}

.testimonial-name,
.testimonial-position {
	display: block;
}

.testimonial-text p:last-child {
	margin: 0;
}

/*========================
	11. COUNTERUP
=========================*/
.counter {
	position: relative;
}

.counter-style-1 .counter {
	font-size: 70px;
	font-weight: 700;
	line-height: 70px;
	display: inline-block;
}

.counter-style-1 .counter-text {
	font-size: 16px;
	font-weight: 500;
}

.counter-style-1.text-black .counter-text {
	color: #000;
}

.counter-style-1 .icon {
	font-size: 45px;
	margin-right: 10px;
}

.counter-style-2 {
	text-align: center;
}

.counter-style-2 .counter-bx {
	margin-bottom: 10px;
}

.counter-style-2 .counter-bx span {
	font-size: 60px;
	font-weight: 500;
	line-height: 70px;
	display: inline-block;
	color: #000;
}

.counter-style-2 .counter-text {
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	color: #627696;
	text-transform: uppercase;
}

.text-white .counter-style-2 .counter-text,
.text-white .counter-style-2 .counter-bx span {
	color: #fff;
}

.counter-style-3 {
	text-align: center;
	padding: 30px 10px;
	border-radius: 8px;
}

.counter-style-3 .counter-text {
	font-size: 14px;
	color: #fff;
}

.counter-style-3 div span {
	font-size: 50px;
	color: #fff;
	line-height: 60px;
	margin-bottom: 5px;
	display: inline-block;
}

/*========================
	12. FOOTER
=========================*/
footer h1,
footer h2,
footer h3,
footer h4,
footer h5,
footer h6,
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a {
	color: #b0b0b0;
}

footer p,
footer strong,
footer b,
footer {
	color: rgba(255, 255, 255, 0.8);
}

footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a,
footer p a {
	color: rgba(255, 255, 255, 0.8);
}

footer .btn-link,
footer a,
footer p a {
	color: rgba(255, 255, 255, 0.8);
	font-size: 14px;
}

footer a:active,
footer a:focus,
footer a:hover {
	color: rgba(255, 255, 255, 0.8);
}

/* widget color */
footer .widget_categories ul li a,
footer .widget_archive ul li a,
footer .widget_meta ul li a,
footer .widget_pages ul li a,
footer .widget_recent_comments ul li a,
footer .widget_nav_menu li a,
footer .widget_recent_entries ul li a,
footer .widget_services ul li a {
	color: rgba(255, 255, 255, 0.8);
}

footer.text-white .widget_categories ul li a,
footer.text-white .widget_archive ul li a,
footer.text-white .widget_meta ul li a,
footer.text-white .widget_pages ul li a,
footer.text-white .widget_recent_comments ul li a,
footer.text-white .widget_nav_menu li a,
footer.text-white .widget_recent_entries ul li a,
footer.text-white .widget_services ul li a,
footer.text-white a,
footer.text-white .footer-bottom,
footer.text-white p,
footer.text-white strong,
footer.text-white b,
footer.text-white .widget .post-title,
footer.text-white .widget-about .ttr-title,
footer.text-white {
	color: rgba(255, 255, 255, 1);
}

footer p {
	margin-bottom: 0px;
}

footer p,
footer li {
	font-size: 16px;
	line-height: 32px;
}

footer#footer {
	background-position: center;
	background-size: cover;
}

footer p {
	line-height: 32px;
	/* margin-bottom: 20px; */
	opacity: 0.8;
	color: #fff;
	font-weight: 300;
}

footer .footer_widget ul {
	list-style: none;
	margin-top: -10px;
	padding: 0;
}

/*widget li in footer*/
footer .widget_categories ul li,
footer .widget_archive ul li,
footer .widget_meta ul li,
footer .widget_pages ul li,
footer .widget_recent_comments ul li,
footer .widget_nav_menu li,
footer .widget_recent_entries ul li,
footer .widget_services ul li {
	border-bottom: 1px dashed rgba(102, 102, 102, 0.3);
}

footer .widget_services ul li {
	transition: all 1s;
	-ms-transition: all 1s;
	-o-transition: all 1s;
	-moz-transition: all 1s;
	-webkit-transition: all 1s;
	padding: 10px 0px 10px 15px;
}

footer .widget_services ul li:hover {
	transform: translateX(10px);
	-moz-transform: translateX(10px);
	-webkit-transform: translateX(10px);
	-o-transform: translateX(10px);
	-ms-transform: translateX(10px);
}

.footer-logo {
	width: 260px;
	display: block;
}

.footer-top {
	background-color: #0a0b0c;
	background-image: url(../images/pattern/pt2.png), url(../images/pattern/pt2.png);
	background-position: center;
	padding: 70px 0 40px;
}

.footer-top .widget {
	margin-bottom: 30px;
}

.footer-bottom {
	background-color: #303030;
	padding: 25px 0;
	color: #b0b0b0;
	/* border-top: 1px solid #c5bbbb70; */
}

.footer-bottom ul {
	margin: 0;
}

.footer-title {
	margin-bottom: 20px;
	color: #fff;
	font-weight: 500;
	text-transform: capitalize;
	font-size: 20px;
	position: relative;
}

.footer_widget ul li a {
	padding: 5px 0;
	display: block;
	font-weight: 400;
	line-height: 28px;
}

.footer-border-top {
	border-top: 1px solid rgb(255 255 255 / 19%);
	padding: 25px 0;
}

@media only screen and (max-width: 1919px) {
	/* .footer-border-top {
		border-top: unset;
	} */
}

@media only screen and (max-width: 991px) {
	.footer-top {
		padding-bottom: 0;
	}

	#content-area>div.section-area.section-sp3.ovpr-dark.bg-fix.about-media-bx.parallax {
		padding-bottom: unset;
	}
}

@media only screen and (max-width: 760px) {
	.column-reverse {
		flex-direction: column-reverse;
	}
}

.ft-social-bx {
	margin-bottom: 0;
}

.ft-social-bx a {
	margin-right: 0;
	color: #fff;
	width: 45px;
	height: 45px;
	line-height: 45px;
	padding: 0;
	text-align: center;
	font-size: 14px;
}

.ft-social-bx a i {
	margin: 0;
}

.list-2 ul {
	margin: 0;
	padding: 0;
	list-style: none;
	display: table;
	margin-top: -8px;
	margin-bottom: -8px;
}

.list-2 ul li {
	width: 50%;
	float: left;
}

.list-2 ul li a {
	color: #fff;
	font-size: 16px;
	font-weight: 300;
	padding: 8px 0;
	display: block;
	opacity: 0.8;
	line-height: 22px;
}

.footer_talkus p {
	margin-top: -10px;
}

.footer-innner-btm {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	padding: 25px 0;
}

.footer-innner-btm p {
	color: #fff;
	opacity: 0.8;
	font-weight: 300;
	font-size: 14px;
	margin: 0;
	padding-top: 10px;

}

/* Subscribe Form */
.subscribe-form {
	background: #fff;
	color: #000;
	background-image: url(../images/pattern/pt1.png), url(../images/pattern/pt1.png);
	padding: 30px;
	margin-top: -90px;
	border-top: 5px solid #fff;
}

.subscribe-form .footer-title {
	color: #000;
	font-size: 24px;
	margin-bottom: 10px;
	font-weight: 600;
}

.subscribe-form p {
	color: #000;
	font-weight: 500;
	font-size: 15px;
	opacity: 1;
	line-height: 24px;
}

@media only screen and (max-width: 761px) {
	.subscribe-form {
		margin-top: -80px;
	}
}

@media only screen and (max-width: 591px) {
	.subscribe-form {
		margin-top: -60px;
	}

	.footer-innner-btm {
		justify-content: center;
		flex-wrap: wrap;
		display: block;
	}
}

.subscribe-form input {
	background-color: #fff;
	height: 60px;
	padding: 10px 24px;
	margin-right: 0;
	color: #fff;
	font-size: 15px;
	border-radius: 4px !important;
	border: 2px solid rgba(0, 0, 0, 0.3);
	margin-bottom: 16px;
}

.subscribe-form form {
	margin-bottom: 20px;
}

.subscribe-form form .btn {
	height: 60px;
	padding: 0 30px;
	font-size: 18px;
	border-radius: 6px;
	font-weight: 600;
}

/* subscribe box 2 */

.subscribe-box .subscribe-title {
	max-width: 300px;
	width: 300px;
	min-width: 300px;
}

.subscribe-box .subscribe-title h4 {
	font-size: 24px;
	font-weight: 700;
	font-family: Poppins;
	color: #fff;
	margin-bottom: 0;
}

.subscribe-box .form-control {
	background-color: rgba(0, 0, 0, 0);
	color: #fff;
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
	padding-left: 0;
}

.subscribe-box .form-control::-moz-placeholder {
	color: #fff;
	opacity: 0.5;
}

.subscribe-box .form-control:-moz-placeholder {
	color: #fff;
	opacity: 0.5;
}

.subscribe-box .form-control:-ms-input-placeholder {
	color: #fff;
	opacity: 0.5;
}

.subscribe-box .form-control::-webkit-input-placeholder {
	color: #fff;
	opacity: 0.5;
}

.subscribe-box .subscribe-form {
	width: 100%;
	padding-left: 30px;
}

.subscribe-action {
	max-width: 600px;
	margin: auto;
	padding: 10px;
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
	border-radius: 60px;
	background: #fff;
}

.subscribe-action .form-control {
	border: 0;
	padding: 10px 30px;
	font-size: 15px;
}

.bg-dark-gray {
	background-color: #3744a8;
}

/* scroll top btn css */
button.back-to-top {
	border-radius: 4px;
	border-style: solid;
	border-width: 0;
	bottom: 15px;
	box-shadow: 2px 2px 12px -5px #000000;
	color: #fff;
	cursor: pointer;
	height: 40px;
	line-height: 26px;
	margin: 0;
	position: fixed;
	left: 15px;
	text-align: center;
	width: 40px;
	z-index: 99999;
	display: block;
}

/* Footer White */
.footer-white .footer-top.bt0 {
	border-top: 0;

}

.footer-white .footer-top,
.footer-white .footer-bottom {
	background: #fff;
	color: #6f6f6f;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer-white .footer-title {
	color: #000;
}

.footer-white .footer-title::after {
	width: 50px;
	background: var(--primary);
	opacity: 1;
	height: 2px;
}

footer.footer-white .btn-link,
footer.footer-white a,
footer.footer-white p a,
footer.footer-white p,
footer.footer-white strong,
footer.footer-white b,
footer.footer-white {
	color: #6f6f6f;
}

footer.footer-white .btn {
	color: #fff;
}

.pt-exebar {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.pt-social-link,
.pt-btn-join {
	border-left: 1px solid rgba(0, 0, 0, 0.1);
}

/*==== LOADING ====*/
#loading-icon-bx {
	width: 100%;
	height: 100%;
	background-color: #fff;
	position: fixed;
	left: 0;
	top: 0;
	opacity: 1;
	z-index: 999999999;
	background-image: url(../images/loading.svg);
	background-repeat: no-repeat;
	background-size: 80px;
	background-position: center;
}

/*========================
	13. INNER CONTENT CSS
=========================*/
/* About Section */
.service-info-bx {
	margin-top: -215px;
}

.service-bx {
	box-shadow: 0 0 25px 0 rgba(29, 25, 0, 0.25);
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	position: relative;
	background-color: #fff;
}

.service-bx [class*="feature-"] {
	box-shadow: 0 0 25px 0 rgba(29, 25, 0, 0.15);
	margin-top: -30px;
	position: relative;
	top: -40px;
	margin-bottom: -20px;
}

.service-bx .info-bx {
	padding: 30px;
}

.service-bx:hover {
	transform: translateY(-15px);
	-moz-transform: translateY(-15px);
	-webkit-transform: translateY(-15px);
	-ms-transform: translateY(-15px);
	-o-transform: translateY(-15px);
}

.service-icon-bx {
	display: flex;
	align-items: center;
	max-width: 800px;
	margin: auto;
	padding: 100px 0;
	border-bottom: 2px dashed rgba(0, 0, 0, 0.2);
}

.service-icon-bx .service-icon a {
	font-size: 150px;
	background: #e1e3ff;
	border-radius: 100%;
	height: 300px;
	width: 300px;
	display: inline-block;
	text-align: center;
	line-height: 300px;
}

.service-icon-bx .service-info {
	padding: 0 0 0 50px;
	max-width: 400px;
	margin-left: auto;
}

.service-icon-bx:nth-child(2n) {
	flex-direction: row-reverse;
}

.service-icon-bx:nth-child(2n) .service-info {
	margin-right: auto;
	margin-left: 0;
	padding-left: 0;
	padding-right: 30px;
}

.service-icon-bx .service-icon {
	position: relative;
}

.service-icon-bx .service-icon:after {
	content: attr(data-name);
	font-size: 18px;
	font-weight: 700;
	font-family: 'roboto', sans-serif;
	position: absolute;
	line-height: 50px;
	top: 50%;
	right: -30px;
	width: 60px;
	transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	height: 60px;
	background: var(--primary);
	border-radius: 100%;
	text-align: center;
	color: #fff;
	border: 5px solid #fff;
}

.service-icon-bx:nth-child(2n) .service-icon:after {
	right: auto;
	left: -30px;
}

.service-icon-bx:first-child {
	padding-top: 40px;
}

.service-icon-bx:last-child {
	border: 0;
	padding-bottom: 10px;
}

.service-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.service-list li a {
	background: #fff;
	display: block;
	margin-bottom: 8px;
	padding: 18px 30px;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 2px;
	border-radius: 4px;
	position: relative;
	z-index: 1;
	transition: all 0.5s;
	-moz-transition: all 1s;
	-webkit-transition: all 1s;
	-ms-transition: all 1s;
	-o-transition: all 1s;
}

.service-list li.active a {
	background-color: var(--primary);
	color: #fff;
	box-shadow: 10px 10px 30px 0 rgba(35, 46, 255, 0.2);
}

.service-list li a:after {
	content: "";
	height: 100%;
	background-color: var(--primary);
	width: 0;
	position: absolute;
	right: 0;
	transition: all 0.5s;
	-moz-transition: all 1s;
	-webkit-transition: all 1s;
	-ms-transition: all 1s;
	-o-transition: all 1s;
	z-index: -1;
	top: 0;
	transform-origin: right;
	-moz-transform-origin: right;
	-webkit-transform-origin: right;
	-ms-transform-origin: right;
	-o-transform-origin: right;
	border-radius: 4px;
}

.service-list li:hover a {
	color: #fff;
}

.service-list li:hover a:after {
	transform-origin: left;
	-moz-transform-origin: left;
	-webkit-transform-origin: left;
	-ms-transform-origin: left;
	-o-transform-origin: left;
	width: 100%;
	right: auto;
	left: 0;
}

.brochure-bx {
	background-color: var(--primary);
	padding: 50px 40px;
	border-radius: 4px;
	overflow: hidden;
	position: relative;
	z-index: 1;
}

.brochure-bx .title {
	font-size: 30px;
	line-height: 40px;
	color: var(--sc-primary);
}

.brochure-bx p {
	font-size: 18px;
	color: #fff;
	line-height: 32px;
}

.brochure-bx:after {
	z-index: -1;
	content: "\f100";
	font-family: "Flaticon";
	position: absolute;
	color: #fff;
	font-size: 200px;
	bottom: 0;
	line-height: 200px;
	opacity: 0.1;
}

.service-textarea p {
	font-size: 17px;
	line-height: 30px;
	color: #32345b;
}

/* Recent News */
.recent-news {
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
	background: #fff;
}

.recent-news.blog-lg {
	box-shadow: none;
}

.recent-news.blog-lg .info-bx {
	border: 0;
	padding: 20px 0 0 0;
}

.blog-post .post-title,
.recent-news .post-title {
	margin-top: 0px;
	margin-bottom: 6px;
	font-family: 'roboto', sans-serif;
}

.recent-news .info-bx {
	padding: 20px;
	border: 1px solid #EEEEEE;
}

.comments-bx {
	margin-left: auto;
	color: #000;
}

.comments-bx i {
	margin-right: 5px;
}

.post-extra {
	display: flex;
	margin-top: 15px;
}

.media-post {
	margin-bottom: 15px;
}

.media-post,
.post-tag {
	margin-bottom: 5px;
}

.media-post li {
	list-style: none;
	display: inline-block;
	font-size: 13px;
	text-transform: capitalize;
	margin-right: 5px;
}

.media-post li a {
	color: var(--primary);
	vertical-align: middle;
	text-transform: capitalize;
	font-size: 14px;
	letter-spacing: 1px;
	font-weight: 600;
}

.media-post li a i {
	margin-right: 5px;
	font-size: 13px;
}

.post-tag li {
	display: inline-block;
	font-size: 14px;
	margin-bottom: 5px;
	list-style: none;
}

.post-title a {
	color: #061538;
}

.blog-post p,
.recent-news p {
	margin-bottom: 10px;
	font-size: 14px;
	color: #242424;
}

.testimonial-carousel .owl-stage {
	padding-left: 0px !important;
}

.recent-news-carousel .owl-nav {
	position: absolute;
	top: 50%;
	left: 25px;
	width: 50px;
	transform: translateY(-50%);
}

.courses-carousel .owl-nav .owl-next,
.courses-carousel .owl-nav .owl-prev {
	background-color: var(--primary);
	margin: 0 5px !important;
}

.courses-carousel .owl-nav .owl-next:hover,
.courses-carousel .owl-nav .owl-prev:hover {
	background-color: var(--sc-primary);
}

.testimonial-carousel.owl-btn-1 .slick-arrow:before {
	color: #000;
	line-height: 50px;
	font-size: 28px;
}

.testimonial-carousel.owl-btn-1 .slick-arrow:hover:before {
	color: #fff;
}

.testimonial-carousel.owl-btn-1 .slick-arrow {
	line-height: 50px;
	height: 50px;
	width: 50px;
	border-radius: 50px;
	background-color: var(--sc-primary);
	color: #000;
	text-align: center;
	margin: 0 5px !important;
}

.testimonial-carousel .slick-arrow:hover {
	background-color: var(--primary);
	color: #fff;
}

.courses-carousel-2 .owl-dots,
.testimonial-carousel-2 .owl-dots {
	text-align: center;
	margin-top: 15px;
}

.courses-carousel-2 .owl-dots .owl-dot,
.testimonial-carousel-2 .owl-dots .owl-dot {
	width: 15px;
	height: 4px;
	background: #000;
	display: inline-block;
	margin: 0 4px;
	cursor: pointer;
	border-radius: 2px;
	opacity: 0.2;
}

.courses-carousel-2 .owl-dots .owl-dot.active,
.testimonial-carousel-2 .owl-dots .owl-dot.active {
	opacity: 1;
	background: var(--primary)
}

@media screen and (min-width: 1400px) {
	.testimonial-carousel .slick-arrow.slick-prev {
		left: -70px;
		top: 50%;
		transform: translateY(-90%);
	}

	.testimonial-carousel .slick-arrow.slick-next {
		left: -70px;
		top: 50%;
		transform: translateY(40%);
	}
}

/* heading-bx */
.heading-bx.left .title-head {
	margin-bottom: 10px;
	margin-top: 0;
	line-height: 32px;
	padding-left: 10px;
	border-left: 5px solid var(--primary);
}

.heading-bx.left p {
	max-width: 500px;
	margin-bottom: 0;
}

.title-head span {
	font-weight: 400;
}

.heading-bx.style1 {
	font-family: Poppins;
}

.heading-bx.style1 .title-head {
	color: #3f3e85;
	font-size: 40px;
	line-height: 50px;
	font-family: Poppins;
	font-weight: 700;
	margin-bottom: 0;
}

.heading-bx.style1 p {
	color: #6c7079;
	margin-bottom: 0;
}

.heading-bx.style1.text-white .title-head {
	color: #fff;
}

.heading-bx.style1.text-white p {
	color: #fff;
	opacity: 0.7;
}

/* section space */
.section-sp1 {
	padding-top: 100px;
	padding-bottom: 70px;
}

.section-sp2 {
	padding-top: 100px;
	padding-bottom: 100px;
}

.section-sp3 {
	padding-top: 70px;
	padding-bottom: 240px;

}

.section-sp4 {
	padding-top: 50px;
	padding-bottom: 50px;
}

.section-sp5 {
	padding-top: 5px;
	padding-bottom: 200px;
	padding-right: 15px;
	padding-left: 15px;
}

/* Event Box */
.event-bx.card {
	background: transparent;
}

.faq-bx .event-bx .card-header {
	cursor: pointer;
	position: relative;
	padding: 33px 50px 30px 120px !important;
	vertical-align: middle;
	margin-bottom: 0 !important;
	box-shadow: 0px 0px 0 3px rgba(0, 0, 0, 0.05);
	background: #fff;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
}

.faq-bx .event-bx .card-header:after {
	content: "\f107";
	font-family: FontAwesome;
	position: absolute;
	right: 30px;
	font-size: 30px;
	top: 42px;
	color: #000;
	opacity: 0.4;
}

.event-bx .card-header .event-mamber {
	width: 70px;
	height: 70px;
	border-radius: 50%;
	position: absolute;
	left: 30px;
	top: 30px;
}

.event-bx .card-header .event-date {
	text-transform: uppercase;
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 2px;
	color: var(--primary);
}

.event-bx .card-body {
	box-shadow: 0px 0px 0 3px rgba(0, 0, 0, 0.05);
}

.event-map {
	margin-bottom: 30px;
}

.event-map iframe {
	height: 400px;
}

.event-bx .card-body p {
	font-size: 18px;
	line-height: 32px;
}

.event-bx .card-header .title {
	margin-bottom: 5px;
}

.place-bx {
	padding: 20px 30px;
	background: #eef2f8;
	color: #fff;
	border-radius: 8px;
}

.place-bx .title {
	color: var(--primary);
	font-size: 22px;
	text-transform: uppercase;
	margin-bottom: 4px;
	line-height: 30px;
}

.place-bx span {
	color: #5a5b83;
	font-size: 16px;
	font-weight: 500;
	display: block;
	line-height: 28px;
}

.event-bx:hover .card-header {
	box-shadow: 5px 5px 30px 3px rgba(45, 46, 104, 0.15);
}

.event-bx.style1 {
	display: flex;
	align-items: stretch;
	box-shadow: 0 0 30px 0 rgba(0, 86, 106, 0.15);
	border-radius: 6px;
	overflow: hidden;
	margin-bottom: 30px;
	background: #fff;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	position: relative;
}

.event-bx.style1:hover {
	transform: translateY(-5px) scale(1.02);
	-moz-transform: translateY(5px) scale(1.02);
	-o-transform: translateY(-5px) scale(1.02);
	-webkit-transform: translateY(-5px) scale(1.02);
	-ms-transform: translateY(-5px) scale(1.02);
}

.event-bx.style1 .event-title a {
	color: #3f3e85;
}

.event-bx.style1 .action-box {
	max-width: 500px;
	width: 500px;
	display: flex;
}

.event-bx.style1 .info-bx {
	padding: 50px;
}

.event-bx.style1 .action-box img {
	object-fit: cover;
	height: 100%;
}

.event-bx.style1 .event-time {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 0;
	margin-right: 0;
}

.event-time .event-date {
	font-size: 55px;
	font-family: rubik;
	font-weight: 700;
	line-height: 55px;
}

/* Event Form */
.event-form {
	background: #232eff;
	padding: 30px 30px;
	border-radius: 4px;
}

.event-form.contact-bx textarea.form-control {
	height: 150px;
}

.event-form .heading-bx {
	margin-bottom: 20px;
}

.event-form .btn-secondry:active,
.event-form .btn-secondry:hover,
.event-form .btn-secondry:focus {
	background-color: #181b4a;
}


/* Time Line */
.timeline-nav {
	top: 150px;
}

.timeline-nav ul {
	margin: 0;
	padding: 0 30px 0 0;
	list-style: none;
	text-align: right;
}

.timeline-nav ul li {
	display: block;
	width: 100%;
}

.timeline-nav ul li a {
	display: block;
	width: 100%;
	font-size: 22px;
	font-weight: 700;
	color: var(--primary);
	opacity: 0.3;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	padding: 0 0;
	text-decoration: underline;
	padding: 5px 0;
}

.timeline-nav ul li a.active {
	opacity: 1;
	font-size: 44px;
	color: var(--primary);
	padding: 10px 0;
}

.timeline-box {
	margin-bottom: 60px;
}

.timeline-box p {
	font-size: 18px;
	line-height: 32px;
	color: #404153;
}

/* Time Line 2 */
.timeline-view {
	position: relative;
}

.timeline-view:after {
	content: '';
	position: absolute;
	width: 6px;
	background-color: white;
	top: 0;
	bottom: 0;
	left: 50%;
	margin-left: -3px;
}

.timeline-area {
	padding: 10px 40px;
	position: relative;
	background-color: inherit;
	width: 50%;
}

.timeline-area:after {
	content: '';
	position: absolute;
	width: 26px;
	height: 26px;
	right: -13px;
	background-color: white;
	border: 4px solid var(--primary);
	top: 15px;
	border-radius: 50%;
	z-index: 2;
	box-shadow: 0 0 0 5px rgba(45, 46, 255, 0.1), 0 0 0 10px rgba(45, 46, 255, 0.1);
}

.timeline-view .left {
	left: 0;
}

.timeline-view .right {
	left: 50%;
}

.timeline-view .left:before {
	content: " ";
	height: 0;
	position: absolute;
	top: 22px;
	width: 0;
	z-index: 1;
	right: 30px;
	border: medium solid white;
	border-width: 10px 0 10px 10px;
	border-color: transparent transparent transparent white;
}

.timeline-view .right:before {
	content: " ";
	height: 0;
	position: absolute;
	top: 22px;
	width: 0;
	z-index: 1;
	left: 30px;
	border: medium solid white;
	border-width: 10px 10px 10px 0;
	border-color: transparent white transparent transparent;
}

.timeline-view .right:after {
	left: -13px;
}

.timeline-content {
	padding: 20px 30px;
	background-color: white;
	position: relative;
	border-radius: 6px;
	box-shadow: 0 10px 40px 0 rgba(237, 244, 255, 0.75);
}

@media screen and (max-width: 600px) {
	.timeline-view:after {
		left: 31px;
	}

	.timeline-area {
		width: 100%;
		padding-left: 70px;
		padding-right: 25px;
	}

	.timeline-area:before {
		left: 60px;
		border: medium solid white;
		border-width: 10px 10px 10px 0;
		border-color: transparent white transparent transparent;
	}

	.timeline-view .left:after,
	.timeline-view .right:after {
		left: 15px;
	}

	.timeline-view .right {
		left: 0%;
	}
}

/* Testimonials */
.testimonial-bx {
	background-color: #fff;
	padding: 30px;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
}

.testimonial-bx:before,
.testimonial-bx:after {
	position: absolute;
	content: "";
	background: var(--sc-primary);
}

.testimonial-bx:before {
	height: 100%;
	width: 10px;
	right: 0;
	top: 0;
}

.testimonial-bx:after {
	height: 200px;
	width: 200px;
	right: -80px;
	bottom: -80px;
	border-radius: 200px;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
}

.testimonial-bx .testimonial-content p,
.testimonial-bx .testimonial-info p {
	margin: 0;
}

.testimonial-bx .testimonial-info h5 {
	font-weight: 600;
	margin-bottom: 0;
	color: var(--primary);
}

.testimonial-bx .testimonial-info {
	margin-top: 15px;
}

.testimonial-bx .testimonial-info:after {
	content: "\f10e";
	position: absolute;
	font-family: fontawesome;
	font-size: 60px;
	color: var(--primary);
	right: 25px;
	bottom: 0;
	z-index: 9;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
}

.testimonial-thumb {
	width: 80px;
	border-radius: 50px;
	overflow: hidden;
	border: 3px solid #fff;
	box-shadow: 0 0 8px 5px rgba(0, 0, 0, 0.1);
	position: absolute;
	left: 0;
	z-index: 9;
}

.testimonial-bx:hover .testimonial-info:after {
	opacity: 0;
}

.testimonial-bx .testimonial-content p {
	color: #303030;
	font-size: 16px;
}

.testimonial-bx .testimonial-pic {
	background: #FFF;
	width: 80px;
	height: 80px;
	position: absolute;
	right: -100px;
	border-radius: 100%;
	overflow: hidden;
	bottom: -100px;
	z-index: 10;
	border: 3px solid #fff;
	box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.15);
	transform: rotate(360deg);
	-moz-transform: rotate(360deg);
	-webkit-transform: rotate(360deg);
	-ms-transform: rotate(360deg);
	-o-transform: rotate(360deg);
	display: block;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
}

.testimonial-bx:hover .testimonial-pic {
	right: 20px;
	bottom: 20px;
	transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-webkit-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-o-transform: rotate(0deg);
}

.testimonial-bx:hover:after {
	height: 200px;
	width: 200px;
	right: -110px;
	background-color: var(--primary);
	bottom: -110px;
}

.case-studies,
.our-partner,
.team-area,
.news-area {
	position: relative;
	z-index: 2;
}

.our-partner {
	z-index: 3;
}

.news-area {
	overflow: hidden;
}

.news-area:before {
	content: attr(data-name);
	font-size: 160px;
	line-height: 160px;
	left: 0;
	letter-spacing: 10px;
	position: absolute;
	top: 60px;
	z-index: -1;
	font-weight: 700;
	text-shadow: 0 5px 40px #edf4ff;
	color: #eef2f8;
	-webkit-animation: 15s scrollup infinite linear;
	-moz-animation: 15s scrollup infinite linear;
	-o-animation: 15s scrollup infinite linear;
	-ms-animation: 15s scrollup infinite linear;
	animation: 15s scrollup infinite linear;
	white-space: nowrap;
}

.case-studies:before,
.our-partner:before,
.team-area:before {
	content: none;
	font-size: 220px;
	line-height: 210px;
	left: 0;
	letter-spacing: 10px;
	position: absolute;
	bottom: -28px;
	z-index: -1;
	font-weight: 700;
	text-shadow: 0 5px 40px #edf4ff;
	color: #fff;
	width: 100%;
	text-align: center;
}

.our-partner:before {
	text-align: left;
	bottom: auto;
	top: 0;
}

.case-studies:before {
	text-align: center;
	bottom: auto;
	top: -20px;
}

/* Image Move Css */
@keyframes scrollup {
	100% {
		transform: translateX(-3000px);
	}
}

/* testimonial-bx style1 */
.testimonial-bx.style1 {
	background: #6021ff;
	background: -moz-linear-gradient(45deg, #6021ff 11%, #0066ff 85%);
	background: -webkit-linear-gradient(45deg, #6021ff 11%, #0066ff 85%);
	background: linear-gradient(45deg, #6021ff 11%, #0066ff 85%);
	border: 0;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6021ff', endColorstr='#0066ff', GradientType=1);
	padding: 30px 20px 50px 30px;
	margin-left: 0;
	margin-bottom: 40px;
	border-radius: 15px;
}

.active .testimonial-bx.style1 {
	opacity: 0.3;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
}

.center.active .testimonial-bx.style1 {
	opacity: 1;
}

.testimonial-bx.style1 .testimonial-thumb {
	bottom: 10px;
	left: 30px;
	width: 60px;
	border: 5px solid #fff;
}

.testimonial-bx.style1 .testimonial-content p {
	color: #fff;
	opacity: 0.8;
}

.testimonial-bx.style1 .testimonial-info p,
.testimonial-bx.style1 .testimonial-info h5 {
	color: #fff;
}

.testimonial-bx.style1 .testimonial-info:after {
	color: #fff;
	opacity: 0.2;
}

.testimonial-bx.style2 {
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
}

.testimonial-bx.style2.blue {
	background: #f5f5ff;
}

.testimonial-bx.style2.orange {
	background: #fff9f3;
}

.testimonial-bx.style2.red {
	background: #fff3f3;
}

.testimonial-bx.style2.red .testimonial-info:after {
	background: #fc6765;
	box-shadow: 10px 10px 50px 0px #fc6765;
	color: #fff;
}

.testimonial-bx.style2.orange .testimonial-info:after {
	background: #ffa143;
	box-shadow: 10px 10px 50px 0px #ffa143;
	color: #fff;
}

.testimonial-bx.style2.blue .testimonial-info:after {
	background: #675fff;
	box-shadow: 10px 10px 50px 0px #675fff;
	color: #fff;
}

.testimonial-bx.style2:before,
.testimonial-bx.style2:after {
	content: none;
}

.testimonial-bx.style2 .title {
	margin-bottom: 10px;
}

.testimonial-rating {
	color: #ffb944;
	margin-bottom: 12px;
}

.testimonial-bx.style2 .testimonial-thumb {
	position: absolute;
	width: 60px;
	border: 0;
	left: 0;
	box-shadow: none;
}

.testimonial-bx.style2 .client-info {
	position: relative;
	padding-left: 80px;
	margin-top: 30px;
}

.testimonial-bx.style2 .client-info .name {
	font-size: 18px;
	line-height: 26px;
	margin-bottom: 4px;
}

.testimonial-bx.style2 .testimonial-info {
	margin-top: 0;
	padding: 5px 0;
}

.testimonial-bx.style2 .testimonial-info p {
	font-size: 14px;
	line-height: 22px;
	text-transform: uppercase;
	font-weight: 600;
	color: #6d747e;
}

.testimonial-bx.style2 .testimonial-info:after {
	font-size: 20px;
	right: 0;
	bottom: 0;
	background: #e2e3ff;
	width: 50px;
	height: 50px;
	text-align: center;
	line-height: 50px;
	border-radius: 50px;
}

.testimonial-bx.style2:hover .testimonial-info:after {
	opacity: 1;
}

.testimonial-bx.style2:hover {
	transform: translateY(-10px);
}

/* testimonial-bx style1 END */
.ovbl-middle:after,
.ovbl-light:after,
.ovbl-dark:after {
	background: #000;
}

.our-story {
	position: relative;
	z-index: 1;
}

.our-story:after {
	width: 50%;
	content: "";
	height: 100%;
	position: absolute;
	left: 50%;
	background-color: #fff;
	top: 0;
	z-index: -1;
}

.our-story .style1 p {
	margin-bottom: 20px;
}

.our-story .heading-bx.style1 .title-head {
	margin-bottom: 10px;
}

.video-bx {
	position: relative;
}

.video-bx .video-media-bx a {
	width: 80px;
	height: 80px;
	border-radius: 80px;
	line-height: 80px;
	font-size: 24px;
}

.modalVideoClosebtn {
	top: 0;
	right: -45px;
	position: absolute;
	z-index: 2;
	display: inline-block;
	width: 35px;
	height: 35px;
	overflow: hidden;
	border: none;
	background: transparent;
}

.modalVideoClosebtn::before {
	content: "";
	position: absolute;
	height: 2px;
	width: 100%;
	top: 50%;
	left: 0;
	background: #fff;
	border-radius: 5px;
	margin-top: -6px;
	transform: rotate(45deg);

}

.modalVideoClosebtn::after {
	content: "";
	position: absolute;
	height: 2px;
	width: 100%;
	top: 50%;
	left: 0;
	background: #fff;
	border-radius: 5px;
	margin-top: -6px;
	transform: rotate(-45deg);
}

@media screen and (max-width: 934px) {
	.modalVideoResponsive {
		width: 700px;
		height: 400px;
	}

	.modalVideoClosebtn {
		top: -41px;
		right: 0px;
	}
}

@media screen and (max-width: 670px) {
	.modalVideoResponsive {
		width: 450px;
		height: 250px;
	}
}

@media screen and (max-width: 550px) {
	.modalVideoResponsive {
		width: 350px;
		height: 200px;
	}
}

@media screen and (max-width: 414px) {
	.modalVideoResponsive {
		width: 400px;
		height: 220px;
	}

	.modalVideoClosebtn {
		top: -41px;
		right: 0px;
	}
}

@media screen and (max-width: 414px) {
	.modalVideoResponsive {
		width: 400px;
		height: 225px;
	}

	.modalVideoClosebtn {
		top: -41px;
		right: 10px;
	}
}

@media screen and (max-width: 375px) {
	.modalVideoResponsive {
		width: 370px;
		height: 210px;
	}

	.modalVideoClosebtn {
		top: -41px;
		right: 15px;
	}
}

@media screen and (max-width: 280px) {
	.modalVideoResponsive {
		width: 280px;
		height: 220px;
	}

	.modalVideoClosebtn {
		top: -41px;
		right: 15px;
	}
}

.content-center {
	justify-content: center;
}

.counter-style-1 span {
	font-size: 70px;
	font-weight: 700;
	line-height: 70px;
	display: inline-block;
}

.counter-style-1 .counter-text {
	color: #fff;
	font-size: 18px;
	line-height: 40px;
}

.join-content-bx {
	max-width: 800px;
	margin: auto;
}

.join-content-bx h2 {
	font-size: 45px;
	line-height: 65px;
	margin: 0px 0 20px 0;
}

.join-content-bx h4 {
	font-weight: 400;
	font-size: 30px;
}

.join-content-bx p {
	line-height: 24px;
	opacity: 0.8;
}

.choose-bx .choose-bx-in {
	margin-top: -20px;
}

.faq-bx .card {
	border: 0;
	margin-bottom: 20px;
}

.faq-bx .card-header {
	padding: 0;
	border-radius: 4px;
	background: transparent;
	border: 0;
	margin-bottom: -4px;
	z-index: 1;
}

.faq-bx .card-header a {
	box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.02);
	background: #fff;
	color: #000;
	border-radius: 4px;
	font-weight: 600;
	padding: 15px 50px 15px 30px;
	display: block;
	border: 0;
	cursor: pointer;
	position: relative;
}

.faq-bx .card-header a.collapsed:after {
	transform: rotate(0);
	-moz-transform: rotate(0);
	-webkit-transform: rotate(0);
	-ms-transform: rotate(0);
	-o-transform: rotate(0);
}

.faq-bx .card-header a:after {
	content: "\f067";
	font-family: FontAwesome;
	position: absolute;
	right: 20px;
	opacity: 0.8;
	font-weight: normal;
	top: 15px;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
}

.faq-bx .card-header a:hover {
	color: #fff;
	box-shadow: 10px 10px 30px 0 rgb(162 163 177 / 30%);
	background: var(--primary);
}

.faq-bx .card-body {
	font-size: 16px;
	line-height: 30px;
	padding: 30px 30px;
	background: #fff;
	border-radius: 4px;
}

.faq-bx .acod-content {
	margin: 10px 0 0 0;
}

.faq-bx .acod-body {
	border: 0;
}

#content-area>div>div>div.row>div>div>div>div.accordion-item.card-header>button {
	border-style: unset;
	margin: unset;
	padding: unset;
	width: 100%;
	text-align: unset;
}

.accordion-item.card-header>button {
	display: unset;
}


/* MemberShip */
.pricingtable-inner {
	text-align: center;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 0 15px 0 rgba(17, 0, 34, 0.1);
}

.pricingtable-features,
.pricingtable-features li,
.pricingtable-footer {
	border: 0;
}

.text-primary-style {
	color: #ff5622;
}

/* #content-area > div > div.container-max.text-center > div.row.sp20 > div{
	box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
height: 500px;
} */


.avatar-design {
	width: 60% !important;
}

/* COOKIE */
.CookieConsent {
	flex-direction: column;
	background: rgb(20 20 20) !important;
	padding-bottom: 28px;
}

.cookie-content {
	display: flex;
	justify-content: center;
}

#root>div>div.cookie-popup>div {
	align-items: center !important;
}

.cookie-paragraphe {
	width: 50%;
	margin-top: 15px;
	margin-left: 25px;
	margin-right: 25px;
	text-align: center;
}

#rcc-confirm-button {
	/* margin-bottom: 22px !important; */
	margin-left: 25px !important;
	margin: 3px !important
}

#root>div>div.cookie-popup>div>div:nth-child(2) {
	margin: -22px;
}

#root>div>div.cookie-popup>div>div:nth-child(1) {
	margin: unset !important;
	flex: unset !important;
}

@media screen and (max-width: 1280px) {
	.cookie-paragraphe {
		width: 80%;

	}
}

/* END COOKIE */

.pricingtable-features li {
	color: #353535;
	font-size: 14px;
	font-weight: 500;
}

.pricingtable-bx {
	font-size: 120px;
	font-weight: 300;
	line-height: 90px;
	font-family: rubik;
	color: #fff;
}

.priceing-doller {
	vertical-align: top;
	font-size: 30px;
	line-height: 30px;
	color: #fff;
}

.pricingtable-type {
	color: #fff;
}

.pricingtable-type::before {
	content: "/";
	margin-right: 10px;
	color: #fff;
}

.pricingtable-price {
	padding: 20px 20px;
	background-color: transparent;
	border: 0px solid #E9E9E9;
}

.pricingtable-main {
	background: #ff5e14;
	/* Old browsers */
	background: -moz-linear-gradient(45deg, #ff5e14 0%, #ff8e14 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(45deg, #ff5e14 0%, #ff8e14 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg, #ff5e14 0%, #ff8e14 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5e14', endColorstr='#ff8e14', GradientType=1);
	/* IE6-9 fallback on horizontal gradient */
}

.pricingtable-title {
	background-color: rgba(255, 255, 255, 0.2);
	padding: 10px 10px 5px 10px;
}

.pricingtable-title h2 {
	font-size: 22px;
	line-height: 26px;
	font-weight: 600;
}

.pricingtable-title p {
	font-size: 13px;
	line-height: 20px;
	font-weight: 500;
}

.pricingtable-features li:nth-child(2n) {
	background-color: rgba(0, 0, 0, 0.1);
}

.pricingtable-footer {
	background-color: rgba(0, 0, 0, 0.1);
}

.pricingtable-footer .btn {
	font-size: 16px;
	padding: 10px 30px;
}

/* Profile Box */
.profile-bx {
	border: 1px solid #e9e9e9;
	position: sticky;
	top: 100px;
}

.user-profile-thumb {
	width: 100px;
	height: 100px;
	overflow: hidden;
	border-radius: 100px;
	border: 5px solid #fff;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
	margin: 25px auto 15px;
}

.profile-info {
	margin-bottom: 15px;
}

.profile-info h4 {
	font-size: 18px;
	margin-bottom: 0;
}

.profile-info span {
	font-size: 14px;
	color: #717171;
}

.profile-social ul li a {
	width: 35px;
	height: 35px;
	display: block;
	text-align: center;
	border: 1px solid #e9e9e9;
	line-height: 34px;
	border-radius: 40px;
	color: #000;
	font-size: 14px;
}

.profile-social {
	border-bottom: 1px solid #e9e9e9;
	padding-bottom: 15px;
}

.profile-content-bx {
	border: 1px solid #e9e9e9;
}

.profile-tabnav {
	margin: 20px 0;
}

.profile-tabnav .nav {
	border: 0;
}

.profile-tabnav .nav li {
	display: block;
	width: 100%;
}

.profile-tabnav .nav li a {
	border: 0;
	color: #8a8a8a;
	font-size: 15px;
	text-align: left;
	padding: 10px 30px;
}

.profile-tabnav .nav li a.active {
	background-color: var(--primary);
	color: #fff;
	border-radius: 0;
}

.profile-tabnav .nav li a i {
	margin-right: 10px;
}

.profile-head h3 {
	font-size: 20px;
	text-transform: uppercase;
	margin-bottom: 0;
	font-weight: 500;
}

.profile-head {
	display: flex;
	padding: 10px 15px 10px 30px;
	border-bottom: 1px solid #e9e9e9;
	align-items: center;
}

.profile-head .feature-filters {
	margin-bottom: 0;
}

.courses-filter .action-card {
	margin-bottom: 30px;
}

.action-card-margin-bottom {
	top: -45px;
}

.portfolio-box .portfolio-media {
	height: 280px;
}


.ajust {
	width: 100%;
	height: 100%;
}

.courses-filter {
	padding: 30px 30px 0 30px;
}

.profile-head .feature-filters.style1 li a {
	font-weight: 400;
	font-size: 15px;
}

.edit-profile h3 {
	font-size: 18px;
	font-weight: 400;
	margin: 0;
}

.edit-profile .help {
	font-size: 12px;
	line-height: 18px;

	display: block;
}

.edit-profile .col-form-label {
	font-size: 14px;
	font-weight: 400;
}

.edit-profile {
	padding: 30px;
}

.edit-profile .form-group {
	margin-bottom: 15px;
}

/* My Account */
.account-form {
	display: flex;
	width: 100%;
	position: relative;
}

.account-head {
	position: sticky;
	left: 0;
	top: 0;
	z-index: 1;
	width: 500px;
	min-width: 500px;
	height: 100vh;
	background-position: center;
	text-align: center;
	align-items: center;
	display: flex;
	vertical-align: middle;
}

.account-head a {
	display: block;
	width: 100%;
}

.account-head:after {
	opacity: 0.9;
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	background: #ff5e14;
	/* Old browsers */
	background: -moz-linear-gradient(45deg, #ff5e14 0%, #ff8e14 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(45deg, #ff5e14 0%, #ff8e14 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg, #ff5e14 0%, #ff8e14 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5e14', endColorstr='#ff8e14', GradientType=1);
	/* IE6-9 fallback on horizontal gradient */
}

.account-form-inner {
	width: 100%;
	align-self: center;
}

.account-container {
	max-width: 400px;
	margin: auto;
	padding: 30px 0;
}

.custom-control-label:before {
	width: 20px;
	height: 20px;
	background-color: transparent;
	border: 2px solid var(--primary);
	top: 2px;
}

.custom-control-label:after {
	width: 20px;
	height: 20px;
	top: 2px;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
	background-color: var(--primary);
}

.account-container .form-forget {
	display: flex;
}

.account-container .form-forget .custom-control-label {
	font-weight: 400;
}

.account-container .btn.button-md {
	padding: 12px 40px;
}

.account-container .form-control {
	border-color: rgba(0, 0, 0, 0.2);
}

.account-container .facebook i,
.account-container .google-plus i {
	border-right: 1px solid #fff;
	padding-right: 10px;
	margin-right: 10px;
}

.account-form .heading-bx p a {
	color: var(--primary);
	text-decoration: underline;
	padding-bottom: 0px;
}

.g-recaptcha,
#rc-imageselect {
	transform: scale(0.99);
	-webkit-transform: scale(0.99);
	-moz-transform: scale(0.99);
	-o-transform: scale(0.99);

	transform-origin: 0 0;
	-o-transform-origin: 0 0;
	-moz-transform-origin: 0 0;
	-webkit-transform-origin: 0 0;
}

@media screen and (max-width: 575px) {

	#rc-imageselect,
	.g-recaptcha {
		transform: scale(0.77);
		-moz-transform: scale(0.77);
		-o-transform: scale(0.77);
		-webkit-transform: scale(0.77);

		transform-origin: 0 0;
		-moz-transform-origin: 0 0;
		-o-transform-origin: 0 0;
		-webkit-transform-origin: 0 0;

	}
}

.recaptcha-box {
	height: 60px;
}

.g-recaptcha>div {
	width: 250px !important;
}

/* Google Recaptcha */
/* error */
.error-page {
	text-align: center;
}

.error-404 {
	height: 100vh;
}

.error-title span {
	width: 145px;
	height: 145px;
	display: inline-block;
	background-image: url(../images/error.gif);
	background-size: 100%;
	margin: 0 10px;
}

.error-404 .account-container {
	max-width: 550px;
}

.error-title {
	font-size: 180px;
	line-height: 180px;
	margin-bottom: 0;
}

.error-page h3 {
	font-size: 50px;
	opacity: 0.2;
}

.error-page h5 {
	font-size: 30px;
	opacity: 1;
	font-weight: 500;
	line-height: 48px;
}

.error-page p {
	font-size: 16px;
	line-height: 26px;
}

.why-chooses-bx .faq-bx {
	max-width: 700px;
	margin: auto;
}

.why-chooses-bx .faq-bx .panel {
	background: #fff;
	margin-bottom: 6px;
}

/* Services Box */
.services-bx:hover:after {
	background: #0066ff;
	/* Old browsers */
	background: -moz-linear-gradient(top, #0066ff 0%, #6021ff 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, #0066ff 0%, #6021ff 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #0066ff 0%, #6021ff 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0066ff', endColorstr='#6021ff', GradientType=0);
	/* IE6-9 */
}

.services-bx .ttr-tilte,
.service-no,
.services-bx p,
.services-bx,
.services-bx:after {
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
}

.services-bx:after {
	content: "";
	position: absolute;
	height: 100%;
	bottom: -100%;
	width: 100%;
	left: 0;
	z-index: -1;
	border-radius: 100%;
}

.services-bx:hover:after {
	border-radius: 0;
	bottom: 0;
	border-radius: 0;
}

.services-bx:hover {
	transform: translateY(-15px);
	-moz-transform: translateY(-15px);
	-o-transform: translateY(-15px);
	-webkit-transform: translateY(-15px);
	-ms-transform: translateY(-15px);
}

.service-no {
	line-height: 70px;
	font-family: Poppins;
	font-size: 70px;
	color: transparent;
	position: absolute;
	top: 40px;
	right: 40px;
	font-weight: 500;
	background-clip: text;
	color: #393dff;
}

.services-bx {
	box-shadow: 0 10px 30px 0 rgba(8, 0, 255, 0.1);
	padding: 50px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	background: #fff;
}

.services-bx p {
	font-size: 14px;
	line-height: 28px;
	color: #69677b;
	font-family: Poppins;
}

.services-bx .ttr-tilte {
	font-family: Poppins;
	font-size: 20px;
	color: #000;
}

.services-bx .feature-lg i {
	line-height: 60px;
}

.services-bx .readmore {
	color: #000;
}

.services-bx .feature-lg .icon-cell {
	color: #000;
}

.services-bx .feature-lg {
	text-align: left;
}

.services-bx:hover .ttr-tilte {
	color: #fff;
}

.services-bx:hover p {
	color: #fff;
	opacity: 0.8;
}

.services-bx:hover .feature-lg .icon-cell,
.services-bx:hover .readmore {
	color: #fff;
}

.services-bx:hover .service-no {
	color: #83ffbe;
}

/* Appointment */
.appointment-box .container {
	z-index: 2;
}

.appointment-box .appoint-bg {
	position: absolute;
	z-index: 1;
	width: 100%;
	bottom: 0;
}

.appointment-box .contact-bx {
	border-radius: 20px;
	padding: 50px;
	box-shadow: 0 0 30px 0 rgba(0, 86, 106, 0.15);
	max-width: 800px;
	margin: auto;
	background: #fff;
	background-image: url(../images/background/bg6.jpg);
	background-size: 100%;
}

.appointment-box .contact-bx .input-group label {
	font-family: Poppins;
	color: #000;
}

/* Agency Slider */
.slideinfo {
	position: absolute;
	bottom: 0;
	display: flex;
	align-items: center;
	transform: rotate(90deg) translateX(-100%);
	-o-transform: rotate(90deg) translateX(-100%);
	-webkit-transform: rotate(90deg) translateX(-100%);
	-moz-transform: rotate(90deg) translateX(-100%);
	-ms-transform: rotate(90deg) translateX(-100%);
	transform-origin: left bottom;
	-o-transform-origin: left bottom;
	-ms-transform-origin: left bottom;
	-webkit-transform-origin: left bottom;
	-moz-transform-origin: left bottom;
}

.slide-social {
	list-style: none;
	margin: 0;
	padding: 0 30px 0 0;

}

.slide-social li {
	display: inline-block;
	color: #fff;
	text-transform: uppercase;
	font-weight: 700;
}

.slide-social li:first-child {
	padding-right: 50px;
	position: relative;
	margin-right: 30px;
}

.slide-social li:first-child:after {
	height: 1px;
	width: 40px;
	right: 0;
	position: absolute;
	content: "";
	background: #fff;
	top: 10px;
}

.slide-social li a {
	color: #fff;
	padding: 0 5px;
	display: block;
	transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
}

.slider-contact.btn {
	font-weight: 700;
	text-transform: uppercase;
	color: #fff;
	border-radius: 0;
	background: var(--primary);
	padding: 15px 40px;
	font-size: 16px;
}

.contact-sidebar {
	position: fixed;
	right: -400px;
	width: 350px;
	height: 100vh;
	background: #fff;
	z-index: 100001;
	top: 0;
	transition: all 0.2s;
	-moz-transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-ms-transition: all 0.2s;
	-o-transition: all 0.2s;

}

.contact-sidebar .inner {
	padding: 40px;
}

.contact-sidebar .title {
	font-size: 19px;
	line-height: 32px;
	color: #000000;
	margin-bottom: 20px;
}

.contact-sidebar .logo {
	margin-bottom: 20px;
}

/* passez en style en ligne */
/* .contact-sidebar .logo img {
	height: 40px;
} */

.contact-sidebar.open {
	right: 0;
	transition: all 0.2s;
	-moz-transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-ms-transition: all 0.2s;
	-o-transition: all 0.2s;
}

.contact-sidebtn.close {
	transition: all 0.2s;
	-moz-transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-ms-transition: all 0.2s;
	-o-transition: all 0.2s;
	display: none;
}

.contact-sidebar.open+.contact-sidebtn.close {
	position: fixed;
	z-index: 100000;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	left: 0;
	top: 0;
	display: block;
	opacity: 1;
	cursor: pointer;
}

.contact-sidebtn.close i {
	background: var(--primary);
	text-shadow: none;
	color: #fff;
	width: 50px;
	height: 50px;
	display: block;
	opacity: 1;
	line-height: 50px;
	font-size: 18px;
	text-align: center;
}

.contact-infolist {
	margin: 0 0 40px 0;
	padding: 25px 0 5px 0;
	list-style: none;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.contact-infolist li {
	position: relative;
	padding: 0px 20px 20px 65px;

}

.contact-infolist li .contact-title {
	color: #404040;
	font-size: 18px;
	line-height: 30px;
	font-weight: 600;
	margin-bottom: 2px;
}

.contact-infolist li img {
	position: absolute;
	left: 0;
	top: 10px;
	/* width: 30px; */

}

.contact-infolist li p,
.contact-infolist li a {
	font-size: 13px;
	line-height: 22px;
	color: #505050;
	font-weight: 500;
	margin-bottom: 0;
}

.contact-sidebar .input-group label {
	font-size: 14px;
	font-weight: 400;
	color: #606060;
	position: absolute;
	top: 10px;
	left: 0;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;

}

.contact-sidebar .input-group textarea.form-control {
	height: 100px;
}

.contact-sidebar .input-group .form-control {
	border-width: 0 0 2px 0;
	background-color: rgba(0, 0, 0, 0);
	padding: 10px 0;
	box-shadow: none;
	height: 45px;
	font-size: 14px;
	border-color: #e1e6eb;
	border-radius: 0;
}

.contact-sidebar .focused .input-group label {
	top: -10px;
	font-size: 11px;
	color: var(--primary);
	z-index: 2;
	font-weight: 600;
}

.contact-sidebar .g-recaptcha,
.contact-sidebar #rc-imageselect {
	transform: scale(0.7);
	-moz-transform: scale(0.7);
	-webkit-transform: scale(0.7);
	-ms-transform: scale(0.7);
	-o-transform: scale(0.7);
}

/* About Us */
.about-year {
	border-radius: 10px;
	padding: 25px 25px;
	width: 240px;
	position: absolute;
	bottom: 30px;
	left: 0;
	box-shadow: 15px 15px 20px 0px rgba(0, 5, 113, 0.2);
}

.about-year h2 {
	font-size: 90px;
	color: var(--sc-primary);
	line-height: 90px;
	margin-bottom: 5px;
}

.about-year h5 {
	color: #fff;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 20px;
	box-shadow: inset 0 -5px 0px 0 var(--sc-primary);
	padding-bottom: 5px;
	margin-bottom: 20px;
}

.about-year h2 span {
	color: #fff;
}

.about-imgbox {
	padding-left: 90px;
	position: relative;
}

.about-imgbox img {
	border-radius: 10px;
}

.videoplay-bx i {
	width: 50px;
	height: 50px;
	background: var(--sc-primary);
	border-radius: 40px;
	text-align: center;
	line-height: 50px;
	color: #000;
}

.videoplay-bx span {
	font-weight: 700;
	text-transform: uppercase;
	color: #fff;
	margin-left: 10px;
}

/* Feature Box */
.feature-bx1 {
	padding: 20px;
	border: 2px solid #eaebff;
	border-radius: 10px;
	background: #fff;
	margin-bottom: 30px;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
}

.feature-container.feature-bx1 p {
	font-size: 15px;
}

.feature-bx1.feature-container .ttr-tilte {
	margin-bottom: 5px;
}

.feature-bx2 .feature-lg i,
.feature-bx1 .icon-cell {
	text-shadow: 2px 2px 0 #fff;
	line-height: 80px;
}

.feature-bx1:hover,
.feature-bx1.active {
	box-shadow: 0 0 30px 0 rgba(0, 10, 105, 0.1);
	border-color: #fff;
}

/* Feature Box 2 */
.feature-bx2 {
	padding: 40px 20px 0 20px;
	border-radius: 8px;
	background: #fff;
	margin-bottom: 30px;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	text-align: center;
	box-shadow: 0 0 30px 0 rgba(0, 10, 105, 0.03);
}

.feature-bx2 .icon-content {
	overflow: unset;
}

.feature-bx2 .ttr-tilte {
	color: var(--primary);
}

.feature-bx2 .icon-content p {
	margin-bottom: 35px;
}

.feature-bx2 .feature-lg i {
	font-size: 80px;
	line-height: 80px;
	color: var(--primary);
}

/* Feature Box 22 */
.feature-bx22 {
	padding: 40px 20px 0 20px;
	border-radius: 8px;
	background: #fff;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	text-align: center;
	box-shadow: 0 0 30px 0 rgba(0, 10, 105, 0.03);
}

.feature-bx22 .icon-content {
	overflow: unset;
}

.feature-bx22 .ttr-tilte {
	color: var(--primary);
}

.feature-bx22 .icon-content p {
	margin-bottom: 35px;
}

.feature-bx22 .feature-lg i {
	font-size: 80px;
	line-height: 80px;
	color: var(--primary);
}

.feature-btn {
	border-top: 1px solid #d9daed;
	display: block;
	margin: 0 -20px;
	padding: 20px 20px;
	text-transform: uppercase;
	color: #000;
	font-weight: 600;
	position: relative;
	overflow: hidden;
	z-index: 1;
}

.feature-btn:after {
	content: "";
	position: absolute;
	top: 0;
	background: var(--primary);
	left: 0;
	display: block;
	width: 100%;
	height: 0;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transform-origin: top;
	z-index: -1;
}

.feature-bx2:hover {
	box-shadow: 0 0 30px 0 rgba(0, 10, 105, 0.1);
	transform: translateY(-10px);
	-moz-transform: translateY(-10px);
	-webkit-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	-o-transform: translateY(-10px);
}

.feature-bx2:hover .feature-btn:after {
	bottom: 0;
	top: auto;
	transform-origin: bottom;
	height: 100%;
}

.feature-bx2:hover .feature-btn {
	color: #fff;
}

.about-video {
	padding-top: 0;
}

.about-video.about-media-bx:before {
	bottom: auto;
	top: 0;
}

.about-video .video-media-bx {
	max-width: 900px;
	margin: auto;
	box-shadow: -20px 0 50px 0 rgba(0, 10, 105, 0.2);

}

.about-video2:after {
	background: #001536;
	opacity: 0.8;
}

.about-video2 .video-media-bx a {
	width: 130px;
	height: 130px;
	line-height: 130px;
	font-size: 28px;
}

.about-video2 .video-media-bx {
	overflow: unset;
	text-align: center;
	height: calc(85vh - 200px);
}

/* Case Studies Box */
.case-studies-box {
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	transition: all 0.5s;
	transform: scale(1);
}

.case-studies-box .studies-info {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 30px;
	background: rgb(15, 15, 15);
	/* Old browsers */
	background: -moz-linear-gradient(top, rgba(15, 15, 15, 0.0) 0%, rgba(0, 0, 0, 1) 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(15, 15, 15, 0.0) 0%, rgba(0, 0, 0, 1) 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(15, 15, 15, 0.0) 0%, rgba(0, 0, 0, 1) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0f0f0f', endColorstr='#000000', GradientType=0);
	/* IE6-9 */
}

.case-studies-box .studies-info .studies-tag {
	color: #fff;
	text-transform: uppercase;
	font-weight: 600;
	opacity: 0.7;
}

.case-studies-box .studies-info .title {
	color: #fff;
	margin-bottom: 0;
}

.case-studies-box .studies-info .title a:hover {
	color: var(--sc-primary)
}

.case-studies-carousel {
	overflow: hidden;
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.case-studies-carousel .slick-list {
	padding-top: 20px;
	padding-bottom: 40px;
	margin-left: -200px;
	margin-right: -200px;
}

.case-studies-carousel .slick-track {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.case-studies-box img {
	display: block;
	margin: 0;
	width: 100%;
	height: auto;
	box-shadow: 0 0 0 rgba(0, 0, 0, 0);
	-webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
	-moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
	transition: all 0.25s;
	-moz-transition: all 0.25s;
	-webkit-transition: all 0.25s;
	-o-transition: all 0.25s;
	transform: scale(1.03);
	transition: all 0.5s;
}

.case-studies-box:hover {
	transform: scale(1.03);
	box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.2);
}

.case-studies-box:hover>img {
	transform: scale(1);
}

@media only screen and (max-width: 576px) {}

/* Client Logo */
.client-logo {
	padding: 20px;
	background: #fff;
	border-radius: 10px;
	border: 2px solid #eef2f8;
}

.client-logo img {
	height: 90px;
	/* width: auto !important; */
	margin: auto;
	opacity: 0.7;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	object-fit: contain;
}

.client-logo:hover img {
	opacity: 1;
}

/* Btn aware */
.btn.btn-aware {
	background: transparent;
	position: relative;
	overflow: hidden;
	z-index: 1;
	border-color: #91ae1d;
}

.btn.btn-aware:hover {
	background-color: transparent;
	border-color: #91ae1d;
}

.btn.black.btn-aware {
	position: relative;
	overflow: hidden;
	background: transparent;
	z-index: 1;
	border-color: #000;
}

.btn.btn-aware span {
	position: absolute;
	display: block;
	width: 0;
	height: 0;
	border-radius: 50%;
	background-color: #91ae1d;
	transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: -1;
}

.btn.black.btn-aware span {
	background-color: #000;
}

.btn-aware:hover span {
	width: 225%;
	height: 562px;
}

/* Video Box */
.video-media-bx {
	overflow: hidden;
	border-radius: 10px;
	position: relative;
}

.video-media-bx a {
	width: 110px;
	height: 110px;
	background: var(--primary);
	border-radius: 100px;
	text-align: center;
	line-height: 110px;
	color: #fff;
	font-size: 40px;
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
}

.border-retouch {
	border-top-right-radius: 17px;
	border-bottom-right-radius: 20px;
}

.about-media-bx:before {
	content: "";
	position: absolute;
	height: 185px;
	background: #eef2f8;
	bottom: 0;
	left: 0;
	z-index: 1;
	width: 100%;
}

.our-partner .heading-bx {
	margin-bottom: 25px;
}

.our-partner-box {
	background: #fff;
	padding: 30px;
	border-radius: 10px;
	display: flex;
	flex-wrap: wrap;
	box-shadow: 0 10px 40px 0 rgba(237, 244, 255, 0.75);
	margin-top: -85px;
	position: relative;
	z-index: 1;
}

.our-partner-box .partner-logo {
	-ms-flex: 0 0 33.33%;
	flex: 0 0 33.33%;
	max-width: 33.33%;
	padding: 35px;
	border-right: 1px solid #e5efff;
	border-bottom: 1px solid #e5efff;
	display: flex;
	align-items: center;
}

.our-partner-box .partner-logo:nth-child(3n) {
	border-right: 0;
}

.our-partner-box .partner-logo:nth-child(7),
.our-partner-box .partner-logo:nth-child(8),
.our-partner-box .partner-logo:nth-child(9) {
	border-bottom: 0;
}

/* Team */
.team-mambers {
	max-width: 700px;
	margin: auto;
}

.team-mambers-area {
	margin-bottom: 50px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	-ms-justify-content: center;
}

.team-mambers-area .item {
	padding: 0 10px 10px;
}

.team-mambers-area .team-media {
	height: 90px;
	width: 90px;
	border-radius: 90px;
	position: relative;
	overflow: hidden;
	margin: auto;
	cursor: pointer;
}

.team-mambers-area .team-media:after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	background: var(--primary);
	width: 100%;
	height: 100%;
	opacity: 0.1;
	transition: all 0.5s;
}

.team-mambers-area .team-media:before {
	color: #fff;
	content: "+";
	font-size: 90px;
	position: absolute;
	z-index: 1;
	text-align: center;
	width: 100%;
	font-weight: 100;
	line-height: 90px;
	transform: scale(0);
	transform: scale(0);
	-o-transform: scale(0);
	-ms-transform: scale(0);
	-webkit-transform: scale(0);
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
}

.team-mambers-area .team-media:hover:before {
	transform: scale(1);
	transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	-webkit-transform: scale(1);
}

.team-mambers-area .team-media:hover:after {
	opacity: 0.5;
}

.team-section {
	position: fixed;
	bottom: -100%;
	left: 0;
	background: #fff;
	z-index: 999;
	width: 100%;
	padding: 50px 15px;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
}

.team-section.active {
	box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.1);
	bottom: 0;
}

.team-box {
	display: flex;
	flex-wrap: wrap;
	max-width: 1140px;
	margin: auto;
	align-items: flex-start;
}

.close-team {
	width: 40px;
	height: 40px;
	position: absolute;
	right: 0;
	background: #000;
	top: -40px;
	text-align: center;
	line-height: 40px;
	color: #fff;
	font-size: 28px;
	font-weight: 300;
}

.team-box .team-media {
	flex: 0 0 20%;
	max-width: 20%;
}

.team-box .team-info {
	flex: 0 0 80%;
	max-width: 80%;
	padding-left: 30px;
}

.team-box .team-info p {
	font-size: 15px;
	line-height: 24px;
	color: #000;
	font-weight: 300;
	margin-bottom: 15px;
}

.team-box .team-info span {
	display: block;
	margin-bottom: 15px;
	text-transform: uppercase;
	font-size: 15px;
	color: #a3b1c7;
	font-weight: 600;
}

.team-title {
	margin-bottom: 5px;
}

.team-social {
	margin-bottom: 0;
}

.team-social li a {
	width: 35px;
	height: 35px;
	display: block;
	border-radius: 30px;
	background: var(--primary);
	line-height: 35px;
	color: #fff;
	text-align: center;
}

.team-social li a:hover {
	background: var(--primary);
	color: #000;
}

.team-mamber .team-media img {
	width: 100%;
}

.team-mamber .team-media .team-gif {
	position: absolute;
	opacity: 0;
	top: 0;
	left: 0;
}

.team-mamber:hover .team-media .team-gif {
	opacity: 1;
}

.team-mamber .team-media {
	overflow: hidden;
	position: relative;
	border-radius: 4px;
}

.team-mamber .team-info {
	text-align: center;
	padding: 20px 10px;
}

.team-mamber .team-info .title {
	font-size: 22px;
	margin-bottom: 5px;
	line-height: 32px;
}

.team-mamber .team-info span {
	color: var(--primary);
	text-transform: uppercase;
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 1px;
}

.team-mamber .team-media ul {
	position: absolute;
	bottom: -50px;
	width: 100%;
	text-align: center;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	padding: 0;
}

.team-mamber .team-media ul li {
	display: inline-block;
	margin: 0 3px;
	font-size: 14px;
}

.team-mamber:hover .team-media ul {
	bottom: 30px;
}

.team-page .team-mamber {
	padding: 0px 20px 10px;
	width: 400px;
}

.team-box2 {
	position: relative;
	box-shadow: 0 0 30px 0px rgba(77, 91, 64, 0.05);
}

.team-box2.team-mamber:hover .team-media ul {
	bottom: 80px;
}

.team-box2 .team-info {
	width: calc(100% - 40px);
	position: absolute;
	bottom: -40px;
	left: 20px;
	border-radius: 8px;
}

.team-box2.team-mamber .team-info span {
	color: #fff;
	opacity: 0.5;
}

.team-box2 .team-info .title a {
	color: #fff;
}

.team-box2.orange .team-social li a {
	background: #ffa143;
	box-shadow: 10px 10px 50px -30px #ffa143;
}

.team-box2.blue .team-social li a {
	background: #675fff;
	box-shadow: 10px 10px 50px -30px #675fff;
}

.team-box2.red .team-social li a {
	background: #fc6765;
	box-shadow: 10px 10px 50px -30px #fc6765;
}

/* Price Box */
.pricebox {
	padding: 50px 30px 30px 30px;
	background: #fff;
	box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	text-align: center;
	position: relative;
	z-index: 1;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
}

.pricebox:hover {
	transform: translateY(-15px);
	-moz-transform: translateY(-15px);
	-webkit-transform: translateY(-15px);
	-ms-transform: translateY(-15px);
	-o-transform: translateY(-15px);
}

.pricebox .title {
	border-radius: 6px;
	font-size: 16px;
	color: #fff;
	font-weight: 500;
	display: inline-block;
	margin-bottom: 30px;
	line-height: 24px;
	padding: 12px 30px;
}

.pricebox .price-list {
	padding: 30px 0;
	margin: 30px -30px 30px -30px;
	list-style: none;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.pricebox .price-list li {
	color: #73819f;
	padding: 10px 0;
	font-weight: 500;
}

.pricebox .price {
	font-size: 50px;
	font-weight: 600;
	color: #273277;
	line-height: 50px;
	display: flex;
	align-items: baseline;
	width: 100%;
	justify-content: center;
}

.pricebox .price sub {
	font-size: 16px;
	line-height: 16px;
	color: #73819f;
	margin: 0px 5px 0px 5px;
	position: relative;
	top: 0px;
}

.pricebox.blue .title {
	background: #675fff;
	box-shadow: 10px 10px 50px -15px #675fff;
}

.pricebox.blue .flaticon,
.pricebox.blue .btn-link {
	color: #675fff;
	font-weight: 500;
}

.pricebox.red .title {
	background: #fc6765;
	box-shadow: 10px 10px 50px -15px #fc6765;
}

.pricebox.red .flaticon,
.pricebox.red .btn-link {
	color: #fc6765;
	font-weight: 500;
}

.pricebox.orange .title {
	background: #ffa143;
	box-shadow: 10px 10px 50px -15px #ffa143;
}

.pricebox.orange .flaticon,
.pricebox.orange .btn-link {
	color: #ffa143;
	font-weight: 500;
}

.pricebox .flaticon {
	position: absolute;
	font-size: 200px;
	right: 0;
	top: 0;
	line-height: 200px;
	z-index: -1;
	color: #675fff;
	opacity: 0.05;
}


/* About Box */
.about-head-bx {
	text-align: center;
}

.about-counter .counter-bx {
	font-size: 110px;
	line-height: 110px;
	font-weight: 700;
	color: var(--primary);
}

.head-in,
.about-counter {
	display: inline-block;
}

.head-in p {
	max-width: 600px;
}

.head-in {
	text-align: left;
	padding-left: 30px;
}

.about-counter h5 {
	font-size: 22px;
	line-height: 22px;
	font-weight: 700;
	color: #5a5b7d;
	border-top: 4px solid #5a5b7d;
	padding-top: 10px;
	display: inline-block;
}

.about-img-box {
	position: relative;
	padding: 0 0px 40px 80px;
}

.about-img-box img {
	border-radius: 10px;
	box-shadow: -10px 0 30px 0 rgba(0, 10, 105, 0.1);
}

.about-img-box img:last-child {
	position: absolute;
	bottom: 0;
	width: 45%;
	left: 0;
}

.video-zoom {
	position: relative;
}

.video-zoom:after,
.video-zoom:before {
	-webkit-animation: 2s scrollup infinite linear;
	-moz-animation: 2s scrollup infinite linear;
	-o-animation: 2s scrollup infinite linear;
	-ms-animation: 2s scrollup infinite linear;
	animation: 2s scrollup infinite linear;
	content: "";
	width: 100%;
	position: absolute;
	height: 100%;
	border: 1px solid var(--sc-primary);
	left: 0;
	border-radius: 100px;
	cursor: pointer;
}

.video-zoom:before {
	-webkit-animation: 2s scrollup1 infinite linear;
	-moz-animation: 2s scrollup1 infinite linear;
	-o-animation: 2s scrollup1 infinite linear;
	-ms-animation: 2s scrollup1 infinite linear;
	animation: 2s scrollup1 infinite linear;
}

@keyframes scrollup {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(1.7);
		opacity: 0
	}
}

@keyframes scrollup1 {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(1.35);
		opacity: 0
	}
}

/* Split Box */
.split-box {
	position: relative;
	overflow: hidden;
	opacity: 0;
}

.split-box>div {
	opacity: 0;
	-webkit-transition: 0s .8s;
	-o-transition: 0s .8s;
	transition: 0s .8s;
}

.split-box:before,
.split-box:after {
	content: "";
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.split-box:before {
	background-color: #3d56f0;
	z-index: 1;
	-webkit-transform: translateX(-101%);
	-ms-transform: translateX(-101%);
	transform: translateX(-101%);
}

.split-box:after {
	background-color: #fff04c;
	z-index: 2;
	-webkit-transform: translateX(-101%);
	-ms-transform: translateX(-101%);
	transform: translateX(-101%);
}

@-webkit-keyframes split-box-animation {
	0% {
		-webkit-transform: translateX(-101%);
		transform: translateX(-101%);
	}

	50% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	100% {
		-webkit-transform: translateX(101%);
		transform: translateX(101%);
	}
}

@keyframes split-box-animation {
	0% {
		-webkit-transform: translateX(-101%);
		transform: translateX(-101%);
	}

	50% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	100% {
		-webkit-transform: translateX(101%);
		transform: translateX(101%);
	}
}

.split-box.split-active,
.split-box.split-active>div {
	opacity: 1;
}

.split-box.split-active:after {
	-webkit-animation: split-box-animation 1.2s 0.2s cubic-bezier(1, 0, 0.54, 0.99) forwards;
	animation: split-box-animation 1.2s 0.2s cubic-bezier(1, 0, 0.54, 0.99) forwards;
}

.split-box.split-active:before {
	-webkit-animation: split-box-animation 1.5s cubic-bezier(0.86, 0, 0.07, 1) forwards;
	animation: split-box-animation 1.5s cubic-bezier(0.86, 0, 0.07, 1) forwards;
}

/* Split Box END */
/* Product Box */

.item-box {
	margin-bottom: 30px;
}

.item-box .item-media {
	overflow: hidden;
	position: relative;
}

.item-box .item-media ul {
	padding: 0;
	list-style: none;
	margin: 0;
	position: absolute;
	bottom: -100px;
	left: 50%;
	transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transition: all 0.8s;
	-moz-transition: all 0.8s;
	-webkit-transition: all 0.8s;
	-ms-transition: all 0.8s;
	-o-transition: all 0.8s;
	width: 100%;
	text-align: center;
}

.item-box:hover .item-media ul {
	bottom: 20px;
}

.item-box .item-media ul li {
	display: inline-block;
	padding: 0 4px;
}

.item-box .item-media ul li a {
	width: 50px;
	height: 50px;
	display: inline-block;
	background: #fff;
	color: #000;
	line-height: 50px;
	text-align: center;
	border-radius: 4px;
	box-shadow: 0 5px 15px 0 rgba(237, 244, 255, 0.75);

}

.item-box .item-media ul li a:hover {
	background: var(--primary);
	color: #fff;
}

.item-box .item-info .title {
	margin-bottom: 5px;
}

.item-box .item-info {
	padding: 20px;
	text-align: center;
}

.item-box .item-icon {
	background-color: rgb(255, 255, 255, 1);
	text-align: center;
	padding-top: 60px;
	padding-bottom: 60px;
	color: #767676;
	font-size: 65px;
}

.item-shop-details {
	text-align: center;
	padding-top: 85px;
	padding-bottom: 85px;
	font-size: 137px;
	border: solid #eeeded;
	border-radius: 10px;
}



.product-full-info .price,
.item-widgets-body .price,
.item-box .price {
	color: #bebebe;
	font-weight: 600;
	font-size: 18px;
}

.product-full-info .price span,
.item-widgets-body .price span,
.item-box .price span {
	color: var(--primary);
	margin-right: 5px;
}

.item-box {
	background: #fff;
	box-shadow: 0 10px 40px 0 rgba(237, 244, 255, 0.75);
	overflow: hidden;
	border-radius: 4px;
}

/* Product details */
.product-full-info .ttr-divider {
	background: rgba(0, 0, 0, 0.15);
}

.product-full-info .price {
	font-size: 20px;
	margin-bottom: 15px;
}

.meta-item,
.product-quantity {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
}

.meta-item .title,
.product-quantity .title {
	margin-bottom: 0;
	width: 120px;
}

.meta-item {
	margin-bottom: 15px;
}

.meta-item a {
	font-weight: 600;
	color: #74758c;
	margin-right: 10px;
}

.quantity {
	max-width: 80px;
}

.product-btn .btn i {
	font-size: 18px;
}

.product-dec {
	color: #74758c;
	font-size: 18px;
	line-height: 30px;
}

.product-btn .btn {
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	padding: 15px 20px;
	margin-right: 10px;
}

.product-btn .btn:hover {
	transform: translateY(-5px);
	-moz-transform: translateY(-5px);
	-webkit-transform: translateY(-5px);
	-ms-transform: translateY(-5px);
	-o-transform: translateY(-5px);
}

.product-info {
	margin-bottom: 50px;
}

.product-description .nav .nav-item a {
	font-size: 20px;
	color: #000;
	font-weight: 600;
	padding: 10px 0px;
	display: inline-block;
	margin-right: 30px;
	border-bottom: 3px solid rgba(0, 0, 0, 0);
	border-width: 0 0 3px;
}

.product-description .tab-pane {
	padding: 20px 0;
}

.product-description .nav .nav-item a.active {
	color: var(--primary);
	border-bottom: 3px solid var(--primary);
}

.product-description .comment-respond {
	padding: 0;
	background: transparent;
	box-shadow: none;
	border-radius: 0;
}

.product-description .comment-form {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;
}

.product-description .comment-form label {
	color: #000;
}

.product-description .comment-form-comment textarea,
.product-description .comment-form-email input,
.product-description .comment-form-author input {
	width: 100%;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 12px 20px;
	border-radius: 4px;
}

.product-description .comment-form-email,
.product-description .comment-form-author {
	flex: 0 0 50%;
	max-width: 50%;
}

.product-description .comment-form-comment,
.product-description .form-submit,
.product-description .comment-form-rating {
	flex: 0 0 100%;
	max-width: 100%;
	margin-bottom: 30px;
}

.comment-form-email,
.product-description .comment-form-author,
.product-description .comment-form-comment,
.product-description .form-submit,
.product-description .comment-form-rating {
	margin-bottom: 30px;
	padding: 0 15px;
}

.product-description .comment-form-comment textarea:hover,
.product-description .comment-form-email input:hover,
.product-description .comment-form-author input:hover,
.product-description .comment-form-comment textarea:focus,
.product-description .comment-form-email input:focus,
.product-description .comment-form-author input:focus {
	outline: none;
	border-color: var(--primary);
}

.product-description .form-submit input {
	padding: 10px 30px;
	border: 0;
	background: var(--primary);
	color: #fff;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 2px;
	border-radius: 4px;
	cursor: pointer;
}

.form-submit input:hover {
	background: var(--sc-primary);
	color: #000;
}

.rating-stars ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.rating-stars ul li {
	display: inline-block;
	color: #959595;
}

.star-rating,
.rating-stars ul li.selected,
.rating-stars ul li.hover {
	color: #ffb805;
}

.product-description .comment-respond .comment-reply-title {
	text-transform: capitalize;
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 30px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding-bottom: 5px;
}

.product-description .commentlist .comment_container {
	display: flex;
	align-items: flex-start;
}

.product-description .commentlist {
	margin: 0;
	padding: 0;
	list-style: none;
}

.product-description .commentlist .comment-text {
	width: 100%;
	padding-left: 20px;
}

.product-description .commentlist .avatar {
	width: 80px;
	max-width: 80px;
	border-radius: 4px;
	background: #fff;
	padding: 4px;
	box-shadow: 0 0 20px 0 rgba(0, 10, 105, 0.1);
}

.product-description .commentlist .author {
	font-size: 20px;
	font-weight: 600;
	color: #000;
	display: block;
}

.product-description .commentlist .meta {
	margin-bottom: 10px;
}

.product-description .commentlist .meta span {
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 2px;
	font-size: 13px;
	color: var(--primary);
}

.product-description .tab-pane p,
.product-description .commentlist .description p {
	color: #74758c;
	font-size: 16px;
	line-height: 30px;
}

.slider-item .item-box {
	margin-bottom: 0;
}

/* checkout */
.card {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #FFF;
	background-clip: border-box;
	border: 1px solid #EFF2F7;
	border-radius: 0.375rem;
}

.card {
	position: relative;
	margin-bottom: 30px;
	-webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
	box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}

.card-header {
	padding: 1rem 1.5rem;
	margin-bottom: 0;
	background-color: transparent;
	border-bottom: 1px solid #EFF2F7;
}

.card-header:first-child {
	border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}

.card-header {
	border-bottom: 1px solid #EFF2F7;
}

.card-body {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 1.5rem;
	color: #696969;

}

.style-card-white {
	background-color: #fff;
	border-radius: 10px;
}

.style-card {
	width: 58%;
	padding-top: 20%;
	margin-left: 21%;
	padding-top: 25%;
	text-align: center;

}

.style-card-type-2 {
	width: 52%;
	padding-top: 20%;
	margin-left: 24%;
	padding-top: 33%;
	text-align: center;
}

.style-card-type-3 {
	width: 58%;
	padding-top: 20%;
	margin-left: 21%;
	padding-top: 17%;
	text-align: center;
}

.checkout-area p {
	font-size: 14px;
}

.checkout-area .form-control {
	padding: 15px 20px;
	height: 45px;
	border-radius: 4px;
	border-color: #E0E6ED;
	border-width: 1px 1px 1px 1px;
	font-size: 15px;
}

.checkout-area .form-group:focus-within .form-control,
.checkout-area .form-group:focus-within .input-group .input-group-text {
	border-color: var(--primary);
}

.checkout-area .input-group .input-group-text {
	background: #fff;
	border-color: #E0E6ED;
	height: 100%;
	border-radius: 0 0.25rem 0.25rem 0;
}

.checkout-area .input-group .form-control {
	border-width: 1px 0 1px 1px;
}

/* ================================
	Shop Filter Mobile 
================================ */
.filter-mobile {
	display: none;
	border: 1px solid rgba(0, 0, 0, 0.1);
	align-items: center;
	text-align: center;
	position: sticky;
	top: 80px;
	background: #fff;
	box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.2);
}

.filter-mobile .filter-bx:first-child {
	border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.filter-mobile .filter-bx {
	width: 50%;
	padding: 10px;
}

.filter-btn {
	color: #404040;
	font-weight: 600;
	border: 2px solid rgba(0, 0, 0, 0.1);
	padding: 3px 10px;
	border-radius: 4px;
}

.filter-btn:hover {
	background-color: #000;
	color: #fff;
}

.filter-btn i {
	margin-right: 5px;
}

.filter-bx .bootstrap-select .dropdown-toggle {
	max-width: 100px;
}

.filter-bx .bootstrap-select .dropdown-toggle {
	border: 2px solid rgba(0, 0, 0, 0.1) !important;
	border-radius: 4px;
	padding: 2px 10px;
	color: #404040;
	font-weight: 600;
	height: 32px;
}

.filter-bx .bootstrap-select.btn-group .dropdown-toggle .caret {
	top: 9px;
}

.filter-btn.close {
	display: none;
	opacity: 0;
}


.cart-box .product-dec {
	padding-left: 15px;
	width: 100%;
	padding-right: 60px;
}

.cart-box .product-img {
	max-width: 50px;
	width: 50px;
}

.cart-box {
	display: flex;
	position: relative;
	padding-bottom: 15px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	margin-bottom: 15px;
}

.cart-box.last,
.cart-box:last-child {
	border: 0;
	padding-bottom: 0;
}

.cart-box .product-dec .title {
	margin-bottom: 0;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
}

.cart-box .product-price {
	position: absolute;
	top: 0;
	right: 0;
	color: var(--primary);
	font-size: 14px;
	line-height: 22px;
	font-weight: 600;
}

.cart-box .product-qua {
	font-size: 14px;
}

.card-body .shipping,
.card-body .subtotal,
.card-body .total {
	display: flex;
	padding: 10px 0px;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.card-body .shipping small,
.card-body .subtotal small,
.card-body .total small {
	font-size: 16px;
	font-weight: 600;
	color: #000;
	flex: 0 0 50%;
}

.card-body .shipping span,
.card-body .subtotal span,
.card-body .total span {
	font-weight: 500;
	font-size: 15px;
	color: #505050;
}

/* Cart Table */
.cart-table img {
	width: 50px;
}

.cart-table {
	padding: 1px
}

.cart-table .media-body {
	padding-left: 15px;
}

.cart-table .media-body .title {
	font-size: 15px;
	margin-bottom: 0;
	font-weight: 500;
	line-height: 22px;
}

.cart-table thead tr th,
.cart-table thead tr:not(.table-divider) {
	border: 0;
	background: rgba(0, 0, 0, 0);
	text-transform: uppercase;
	font-size: 14px;
	color: #000;
}

.cart-table td,
.cart-table th {
	border: 0;
	vertical-align: middle;
}

.cart-table .actions {
	padding-right: 20px;
}

.cart-table .actions a {
	padding: 0 7px;
}

.cart-table tr:not(.table-divider) {
	border: 1px solid rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
	background: #fff;
	vertical-align: middle;

}

.cart-table .price {
	font-size: 14px;
	color: var(--primary);
	font-weight: 500;
}

.cart-table .form-control {
	border-radius: 4px;
	padding: 10px 15px;
}

.cart-table .form-control:focus,
.cart-table .form-control:hover {
	border-color: var(--primary);
}

.cart-table .status {
	font-weight: 500;
	font-size: 14px;
}

.cart-table .total {
	font-size: 14px;
	font-weight: 600;
	color: #000;
}

.cart-table .media-body span {
	font-size: 13px;
	font-weight: 500;
}

.table-divider {
	height: 20px;
}

.cart-table .media {
	display: flex;
}

.cart-table .media .media-body .title {
	margin-right: 5px;
}

/* Contact Us */
.icon-box .ft-social-bx a {
	width: 40px;
	height: 40px;
	line-height: 40px;

}

.icon-box {
	max-width: 300px;
	margin-bottom: 30px;
}

.icon-box .title {
	font-weight: 500;
	margin-bottom: 10px;
	border-bottom: 2px solid rgba(0, 0, 0, 0.1);
	padding-bottom: 15px;
	color: #595b83;
	font-size: 20px;
	display: flex;
	align-items: center;
}

.icon-box .title i {
	font-size: 30px;
	margin-right: 10px;
	color: var(--primary);
}

.icon-box p {
	font-size: 16px;
	line-height: 30px;
	margin-bottom: 10px;
}

.contact-bx .form-control {
	padding: 15px 30px;
	height: 55px;
	border-radius: 4px;
	font-size: 16px;
	box-shadow: 0 0 20px 0 rgba(0, 10, 105, 0.03);
}

.contact-bx .form-control:focus,
.contact-bx .form-control:hover {
	border-color: var(--primary);
}

.contact-bx textarea.form-control {
	height: 300px;
}

.service-img-bx {
	display: flex;
	align-items: center;
	margin-bottom: 60px;
}

.service-img-bx:nth-child(2n) {
	flex-direction: row-reverse;
	text-align: right;
}

.service-img-bx .service-info .title h3 {
	margin-bottom: 10px;
}

.service-img-bx .service-info .title {
	border-left: 5px solid var(--primary);
	padding-left: 30px;
	margin-bottom: 30px;
}

.service-img-bx:nth-child(2n) .title {
	border-right: 5px solid var(--primary);
	padding-left: 0;
	margin-bottom: 30px;
	padding-right: 30px;
	border-left: 0;
}

.service-img-bx .service-info .title p {
	margin-bottom: 0;
}

.service-img-bx .service-media img {
	width: 100%;
}

.service-img-bx .service-media {
	flex: 0 0 65%;
	max-width: 65%;
}

.service-img-bx .video-media-bx {
	border-radius: 4px;
}

.service-img-bx .service-info {
	flex: 0 0 35%;
	max-width: 35%;
	padding: 0 0 0 50px;
}

.service-img-bx:nth-child(2n) .service-info {
	padding: 0 50px 0 0;
}


/* my-account */
.my-account {
	margin: auto;
	width: 450px;
}

.my-account .tab-content form .form-control {
	border: 1px solid #eaeaea;
	height: 50px;
	font-size: 16px;
	padding: 15px 20px;
	border-radius: 5px;
}

.my-account .nav.nav-tabs {
	margin-bottom: 30px;
}

.my-account .forgot-pass {
	float: right;
	padding: 10px 5px;
}

.my-account .nav .nav-item .nav-link {
	background-color: #fff;
	border-right: 1px solid #eaeaea;
}

.my-account .nav .nav-item .nav-link.active {
	background-color: var(--primary);
	color: #fff;
}

.my-account .tab-pane {
	padding: 30px 30px 10px;
	background-color: #fff;
	border-top: 1px solid #eaeaea;
}


/* Portfolio Box */
.portfolio-box {
	overflow: hidden;
	position: relative;
	cursor: pointer;
}

.portfolio-box.style-1 {
	margin-bottom: 40px;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	box-shadow: 0 0 30px 0 rgba(0, 10, 105, 0.03);
	border-radius: 4px;
}

.portfolio-box.style-1:hover {
	transform: translateY(-10px);
	-webkit-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	-o-transform: translateY(-10px);
	-moz-transform: translateY(-10px);
}

.portfolio-box.style-1 .portfolio-media {
	overflow: hidden;
	vertical-align: middle;
}

.portfolio-box.style-3 .portfolio-media img,
.portfolio-box.style-2 .portfolio-media img,
.portfolio-box.style-1 .portfolio-media img {
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	/* transform:scale(1.1); */
	/* -webkit-transform:scale(1.1);
	-ms-transform:scale(1.1);
	-o-transform:scale(1.1);
	-moz-transform:scale(1.1); */
}

.portfolio-box.style-3:hover .portfolio-media img,
.portfolio-box.style-2:hover .portfolio-media img,
.portfolio-box.style-1:hover .portfolio-media img {
	transform: scale(1);
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	-moz-transform: scale(1);
}

.portfolio-box.style-3 .portfolio-info,
.portfolio-box.style-1 .portfolio-info {
	background: #fff;
	padding: 25px 30px;
}

.portfolio-box.style-3 .portfolio-info .title,
.portfolio-box.style-1 .portfolio-info .title {
	font-size: 24px;
	line-height: 35px;
	margin-bottom: 8px;
}

.portfolio-box.style-3 .portfolio-info .exe-title,
.portfolio-box.style-1 .portfolio-info .exe-title {
	text-transform: uppercase;
	font-weight: 600;
	color: var(--primary);
	font-size: 14px;
	letter-spacing: 1px;
}

.portfolio-box.style-2:after {
	content: "";
	background: var(--primary);
	position: absolute;
	width: 100%;
	height: 0;
	top: 0;
	opacity: 0.9;
	z-index: 1;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	transform-origin: top;
}

.portfolio-box.style-2:hover:after {
	transform-origin: bottom;
	height: 100%;
	top: auto;
	bottom: 0;
}

.portfolio-box.style-2 .portfolio-info {
	position: absolute;
	width: 100%;
	top: 50%;
	transform: translateY(-50%);
	text-align: center;
	z-index: 2;
}

.portfolio-box.style-2 .portfolio-info .title {
	font-size: 22px;
	line-height: 30px;
	margin-bottom: 40px;
	color: #fff;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	opacity: 0;
}

.portfolio-box.style-2 .portfolio-info .title a {
	color: #fff;
}

.portfolio-box.style-2 .portfolio-info .exe-title {
	text-transform: uppercase;
	font-weight: 600;
	color: #fff;
	font-size: 14px;
	letter-spacing: 3px;
	opacity: 0;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
}

.portfolio-box.style-2:hover .portfolio-info .title {
	margin-bottom: 5px;
	opacity: 1;
}

.portfolio-box.style-2:hover .portfolio-info .exe-title {
	opacity: 0.6;
}

.portfolio-box.style-1.m-b0,
.portfolio-box.style-2.m-b0,
.portfolio-box.style-3.m-b0 {
	margin-bottom: 0;
}

.portfolio-box.style-3,
.portfolio-box.style-2 {
	margin-bottom: 20px;
	/* box-shadow:0 0 30px 0 rgba(0,10,105,0.03); */
	-webkit-box-shadow: 5px 9px 15px 10px rgba(184, 184, 184, 0.08);
	box-shadow: 5px 9px 15px 10px rgba(184, 184, 184, 0.08);
	-webkit-box-shadow: 5px 9px 15px 10px rgba(184, 184, 184, 0.08);
	/* border-radius:4px; */
}

.container-max {
	max-width: 1500px;
	padding: 0 15px;
	margin: auto;
}

.portfolio-box.style-3 {
	border-radius: 10px;
}

.portfolio-box.style-3 .portfolio-info {
	position: absolute;
	bottom: -100px;
	width: 100%;
	opacity: 0;
	transition: all 0.4s;
	-moz-transition: all 0.4s;
	-webkit-transition: all 0.4s;
	-ms-transition: all 0.4s;
	-o-transition: all 0.4s;
}

.portfolio-box.style-3:hover .portfolio-info {
	opacity: 1;
	bottom: 0;
}

.portfolio-box.style-3:hover .portfolio-media img {
	transform: translateY(-50px);
}

/* Portfolio Carousel 1 Style 1 */
.portfolio-carousel-1.style-1 .portfolio-media img {
	height: calc(100vh - 70px);
	object-fit: cover;
	width: 100%;
}

/* skillbar bar style 1  */
.skillbar {
	position: relative;
	display: inline-block;
	background: rgba(0, 0, 0, 0.15);
	height: 5px;
	width: 100%;
	border-radius: 10px;
	text-align: left;
}

.skillbar-bar {
	height: 5px;
	border-radius: 10px;
	width: 0px;
	background: #000;
	display: inline-block;
	position: absolute;
	top: 0;
	margin-bottom: 0px;
}

.skill-bar-percent {
	position: absolute;
	top: -28px;
	right: 0;
	color: var(--primary);
	font-weight: 600;
	font-size: 14px;
}

.skill-bar-text {
	top: -22px;
	position: absolute;
}

.skillbar-box .title {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 0;
	line-height: 22px;
}

.skillbar-box {
	margin-bottom: 35px;
}

.skillbar-bar-main.white .title {
	color: #fff;
}

.skillbar-bar-main.white .skillbar {
	background: rgba(255, 255, 255, 0.15);
}

.skillbar-bar-main.white .skillbar-bar {
	background: var(--sc-primary);
}

.skillbar-bar-main.white .skill-bar-percent {
	color: var(--sc-primary);
}

/* New */
.media-post {
	padding-left: 0;
}

.header-2 .container-fluid>.menu-logo {
	background-color: #232eff;
	padding: 0 20px;
	width: 110px;
	margin-left: -30px;
	height: 110px;
}

.header-2 .menu-links {
	padding: 20px 0 0 80px;
}

.header-2 .secondary-menu {
	padding: 43px 0;
}

.header-2 .header-contact {
	display: inline-block;
}

.header-2 .header-contact .info-no {
	font-size: 20px;
	margin: 0 0 0 20px;
	line-height: 20px;
	font-weight: 500;
	color: var(--primary);
}

.header-2 .header-contact .info-no strong {
	font-weight: 500;
	color: #73819f;
	margin-right: 5px;
}

@media only screen and (min-width: 991px) {
	.header-2 .menu-links .nav>li>a {
		color: #73819f;
		text-transform: capitalize;
		font-weight: 500;
		font-size: 18px;
		line-height: 18px;
	}
}

.header-2 .sticky-header.active .secondary-menu {
	padding: 28px 0;
}

.header-2 .sticky-header.active .menu-bar {
	background: #fff;
}

.header-2 .sticky-header.active .menu-links {
	padding: 9px 0 7px 80px;
}

.header-2.header-transparent .menu-links .nav>li:hover>a,
.header-2.header-transparent .menu-links .nav>li.active>a {
	color: var(--primary);
}

.header-2.header-transparent .sticky-header.active .container-fluid>.menu-logo {
	padding: 0 15px;
	width: 80px;
	margin-left: -30px;
	height: 80px;

}

.heading-bx.style2 .title-head {
	color: #000;
	font-size: 50px;
	line-height: 70px;
	font-weight: 500;
}

.heading-bx.style2 p {
	font-size: 18px;
	line-height: 32px;
	color: #636780;
}

.about-box .btn.red {
	background: var(--primary);
}

.heading-bx.style2.text-white .title-head {
	color: #fff;
}

.heading-bx.style2.text-white p {
	color: #fff;
	opacity: 0.7;
}

.feature-container.feature-bx3 {
	background: #998f8f;
	box-shadow: 10px 10px 50px -30px #998f8f;
	border-radius: 8px;
	padding: 30px;
	text-align: center;
}

.feature-container.feature-bx3 p,
.feature-container.feature-bx3 .ttr-tilte {
	color: #fff;
}

.feature-container.feature-bx3 .ttr-tilte {
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
}

.feature-container.feature-bx3 .btn-link {
	color: #fff;
	font-weight: 500;
	text-decoration: underline;
}

.feature-container.feature-bx3 p {
	font-size: 13px;
	line-height: 24px;
	margin-bottom: 15px;
}

.counter-style-3.orange,
.team-box2 .team-info.orange,
.project-box .info.orange,
.feature-container.feature-bx3.orange {
	background: #ff5722;
	box-shadow: 10px 10px 50px -30px #ff5722;
}

.counter-style-3.red,
.team-box2 .team-info.red,
.project-box .info.red,
.feature-container.feature-bx3.red {
	background: var(--primary);
	box-shadow: 10px 10px 50px -30px var(--primary);
}

.counter-style-3.blue,
.team-box2 .team-info.blue,
.project-box .info.blue,
.feature-container.feature-bx3.blue {
	background: #544c49;
	box-shadow: 10px 10px 50px -30px #544c49;
}

.counter-style-3.green,
.team-box2 .team-info.green,
.project-box .info.green,
.feature-container.feature-bx3.green {
	background: #998f8f;
	box-shadow: 10px 10px 50px -30px #998f8f;
}

.project-box {
	position: relative;
}

.project-box .media {
	overflow: hidden;
	border-radius: 8px;
	box-shadow: 0px 0px 50px -20px #675fff;
}

.project-box .info {
	background: #675fff;
	box-shadow: 0px 0px 40px 0px rgba(35, 46, 255, 0.1);
	position: absolute;
	bottom: 30px;
	border-radius: 8px;
	left: 0px;
	padding: 30px;
	width: 285px;
}

.project-box .media a {
	position: absolute;
	z-index: 2;
	width: calc(100% - 40px);
	height: calc(100% - 40px);
	color: #fff;
	background: rgba(103, 95, 255, 0.95);
	box-shadow: 10px 10px 50px -30px #675fff;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 20px;
	left: 20px;
	font-size: 50px;
	font-weight: 100;
	transform: scale(0.5);
	opacity: 0;
}

.project-box:hover .media a {
	transform: scale(1);
	opacity: 1;
}

.project-box .info .title {
	color: #fff;
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 8px;
	line-height: 34px;
}

.projects-carousel .owl-item.active.center {
	opacity: 1;
}

.project-box .info p {
	color: #fff;
	opacity: 0.7;
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 20px;
	font-weight: 300;
}

.projects-carousel .slick-arrow {
	text-align: center;
	background: #675fff;
	box-shadow: 10px 10px 30px -10px #675fff;
	border-radius: 4px;
	width: 50px;
	height: 50px;
	line-height: 50px;
	padding: 0;
	margin: 0 50px !important;
	z-index: 9;
}

.projects-carousel .slick-prev:before,
.projects-carousel .slick-next:before {
	line-height: 50px;
}

.projects-carousel {
	padding: 30px 0;
}

/* Home 3 */
.home-bnr {
	padding: 80px 0;
}

.home-bnr-img {
	width: calc(50vw - 15px);
	box-shadow: 0 0 60px 0 rgba(0, 7, 143, 0.15);
	border-radius: 10px 0px 0px 10px;
	overflow: hidden;
}

.home-bnr-title {
	text-align: left;
	padding-right: 50px;
}

.home-bnr-title .title {
	font-weight: 600;
	color: #000228;
	font-size: 45px;
	line-height: 60px;
}

.home-bnr-title p {
	color: #464876;
	font-size: 16px;
}

.search-bx.style-2 .form-control {
	height: 60px;
	padding: 10px 30px;
	border-radius: 8px 0 0 8px;
	font-size: 16px;
	border-width: 1px 0 1px 1px;
}

.search-bx.style-2 .btn {
	font-weight: 400;
	text-transform: capitalize;
	font-size: 16px;
	height: 60px;
	padding: 10px 30px;
	border-radius: 0 8px 8px 0;
}

.feature-bx4 {
	margin-bottom: 40px;
}

.feature-bx4 .feature-box-xs {
	width: 60px;
	height: 60px;
	line-height: 60px;
	border-radius: 16px;
	position: relative;
}

.feature-bx4 .feature-box-xs i {
	font-size: 30px;
}

.feature-bx4 .ttr-tilte {
	font-size: 20px;
	line-height: 30px;
	margin-bottom: 5px;

}

.style3.heading-bx h3.title-head {
	font-size: 40px;
	line-height: 50px;
	font-weight: 600;
}

.style3.heading-bx h2.title-head {
	font-size: 45px;
	line-height: 55px;
	font-weight: 600;
}

.feature-bx4 .feature-box-xs:after,
.feature-bx4 .feature-box-xs:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
	border: 2px solid var(--primary);
	-webkit-border-radius: inherit;
	-khtml-border-radius: inherit;
	-moz-border-radius: inherit;
	-ms-border-radius: inherit;
	-o-border-radius: inherit;
	border-radius: inherit;
	z-index: 1;
	transform: scale(1);
	transition: all 0.5;
}

.feature-bx4:hover .feature-box-xs:before {
	animation: iconbox1;
	animation-iteration-count: infinite;
	animation-duration: 3s;
}

.feature-bx4:hover .feature-box-xs:after {
	animation: iconbox2;
	animation-iteration-count: infinite;
	animation-duration: 3s;
}

.feature-bx4 .feature-box-xs.bg-green:after,
.feature-bx4 .feature-box-xs.bg-green:before {
	border-color: #34bfa3;
}

.feature-bx4 .feature-box-xs.bg-red:after,
.feature-bx4 .feature-box-xs.bg-red:before {
	border-color: #b72525;
}

.feature-bx4 .feature-box-xs.bg-blue:after,
.feature-bx4 .feature-box-xs.bg-blue:before {
	border-color: #00c5dc;
}

.feature-bx4 .feature-box-xs.bg-yellow:after,
.feature-bx4 .feature-box-xs.bg-yellow:before {
	border-color: #ffb822;
}

.feature-bx4 .feature-box-xs.bg-pink:after,
.feature-bx4 .feature-box-xs.bg-pink:before {
	border-color: #ff3c85;
}

.feature-bx4 .feature-box-xs.bg-orange:after,
.feature-bx4 .feature-box-xs.bg-orange:before {
	border-color: var(--primary);
}

@keyframes iconbox1 {

	/*Video Popup*/
	0% {
		opacity: 0.8;
		transform: scale(0.9);
	}

	100% {
		transform: scale(1.5);
		opacity: 0;
	}
}

@keyframes iconbox2 {

	/*Video Popup*/
	0% {
		opacity: 0.8;
		transform: scale(0.9);
	}

	100% {
		transform: scale(1.9);
		opacity: 0;
	}
}

.footer-style2 .footer-top {
	background: #0a0b0c;
	font-weight: 300;
	background-image: url(../images/pattern/pt2.png), url(../images/pattern/pt2.png);
	background-position: center;
}

.footer-style2 .footer-bottom {
	background: #0a0b0c;
	font-size: 14px;
	color: rgba(255, 255, 255, 0.6);
	font-weight: 300;
	background-image: url(../images/pattern/pt2.png), url(../images/pattern/pt2.png);
	background-position: center;
}

.footer-style2 .footer-title {
	font-size: 18px;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.footer-style2 .footer_widget ul li a {
	font-size: 14px;
	color: rgba(255, 255, 255, 0.6);
	font-weight: 300;
}

.footer-style2 .footer_widget ul li a:hover {
	color: var(--primary);
}

.footer-style2 .footer-bottom p {
	font-size: 14px;
	color: rgba(255, 255, 255, 0.6);
	font-weight: 300;
	line-height: 24px;
}

.link-inline {
	margin: 0;
	padding: 0;
	list-style: none;
}

.link-inline li {
	display: inline-block;
	line-height: 24px;
}

.link-inline li a {
	color: #c3c3c3;
	font-weight: 300;
	padding-left: 23px;
	font-size: 14px;
}

.footer-style2 .ft-social-bx a {
	border-radius: 40px;
	font-size: 16px;
	height: 40px;
	width: 40px;
	line-height: 40px;
}

.widget_info ul {
	margin: 0;
	padding: 0;
	list-style: none;
	margin-top: -10px;
}

.widget_info ul li {
	color: rgba(255, 255, 255, 0.6);
	font-size: 14px;
	font-weight: 300;
	line-height: 24px;
	padding: 5px 0;
}

.widget_info ul li strong {
	font-weight: 300;
	margin-right: 5px;
	color: rgba(255, 255, 255, 0.6);
}

/* About */
.about-img-box2 {
	display: flex;
	align-items: center;
	width: calc(50vw - 15px);
	float: right;
}

.about-img-box2 .img-bx1 {
	flex: 0 0 45%;
}

.about-img-box2 .img-bx2 {
	flex: 0 0 55%;
	text-align: right;
}

.about-img-box2 img {
	padding: 15px 15px 15px 15px;
}

.about-img-box2 .img-bx2 img:last-child {
	width: 85%;
}

.heading-bx.style3 ul li {
	font-size: 18px;
	color: #404153;
}

@media only screen and (max-width: 1200px) {
	.account-head {
		width: 350px;
		min-width: 350px;
	}

	.rev-btn {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}

	.pricingtable-bx {
		font-size: 80px;
	}

	.our-partner:before,
	.team-area:before {
		font-size: 170px;
		line-height: 175px;
	}

	.heading-bx h2 {
		font-size: 40px;
		line-height: 50px;
	}

	.about-media-bx .heading-bx h5 {
		font-size: 18px;
		line-height: 30px;
	}

	.counter-style-1 .counter {
		font-size: 45px;
		line-height: 55px;
	}

	.counter-style-1 span {
		font-size: 45px;
		line-height: 55px;
	}

	.counter-style-1 .counter-text {
		font-size: 14px;
		line-height: 28px;
	}

	.about-media-bx .heading-bx {
		margin-top: 0;
	}

	.setResizeMargin {
		padding-left: 30px;
	}

	.testimonial-carousel .owl-nav {
		left: -20px;
	}

	.our-partner .section-sp3 {
		padding-top: 40px;
	}

	#container-responsive-mobile-partner>div>div:nth-child(2) {
		margin-bottom: 50px;
	}

	/* #content-area > div.section-area.section-sp3.ovpr-dark.bg-fix.about-media-bx.parallax{
		padding-bottom: unset;
	} */
	.blog-md .ttr-post-media {
		width: 200px;
	}

	.menu-links .nav>li .add-menu {
		left: -100px;
	}

	.about-img-box {
		position: relative;
		padding: 0 0px 80px 20px;
	}

	.header-2 .menu-links {
		padding: 20px 0 0 20px;
	}

	.header-2 .sticky-header.active .menu-links {
		padding: 9px 0 7px 20px;
	}

	/* Home 3 */
	.style3.heading-bx h3.title-head {
		font-size: 30px;
		line-height: 45px;
	}

	.home-bnr-title .title {
		font-weight: 600;
		color: #000228;
		font-size: 30px;
		line-height: 45px;
	}

	.style3.heading-bx h2.title-head {
		font-size: 30px;
		line-height: 40px;
	}
}

@media only screen and (max-width: 991px) {
	.filter-on .filter-btn.close {
		border: 0;
		z-index: 1;
		position: fixed;
		right: 10px;
		top: 10px;
		color: #fff;
		opacity: 1;
		font-size: 18px;
		display: block;
		transition: all 0.5s;
		-moz-transition: all 0.5s;
		-moz-transition: all 0.5s;
		-ms-transition: all 0.5s;
		-o-transition: all 0.5s;
		opacity: 1;
	}

	.filter-btn.close:hover {
		background: rgba(255, 255, 255, 0.1);
	}

	.filter-mobile {
		display: flex;
		margin-bottom: 30px;
	}

	.filter-left {
		padding: 20px 20px;
		width: 280px;
		position: fixed !important;
		left: -310px;
		top: 0;
		background: #fff;
		z-index: 9999999;
		box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
		overflow-y: scroll;
		height: 100%;
		transition: all 0.5s;
		-moz-transition: all 0.5s;
		-moz-transition: all 0.5s;
		-ms-transition: all 0.5s;
		-o-transition: all 0.5s;
	}

	.filter-left.filter-on {
		left: 0;
	}

	.filter-bar {
		top: 80px;
		position: sticky;
		z-index: 999;
	}

	.filter-bar.sticky-top .side-bar {
		z-index: 9;
		top: 0;
		position: unset;
	}

	.sticky-top.filter-bar {
		z-index: 99999999;
		top: 80px;
		position: sticky;
	}

	.filter-left:after {
		content: "";
		position: fixed;
		width: 0;
		background: #000;
		height: 100%;
		z-index: -1;
		left: 0;
		top: 0;
		opacity: 0;
		transition: all 0.5s;
		-moz-transition: all 0.5s;
		-moz-transition: all 0.5s;
		-ms-transition: all 0.5s;
		-o-transition: all 0.5s;
	}

	.filter-left.filter-on:after {
		width: 1000px;
		left: 280px;
		opacity: 0.8;
	}

	.service-info-bx {
		margin-top: 0px;
		padding-top: 80px;
	}

	.account-head {
		width: 100%;
		min-width: 100%;
		height: 200px;
	}

	.account-form {
		display: block;
	}

	.account-container {
		padding: 50px 20px;
	}

	.our-partner:before,
	.team-area:before {
		font-size: 100px;
		line-height: 100px;
		bottom: -15px;
	}

	.our-partner-box {
		margin-top: 40px;
	}

	.blog-md.md-large .post-title {
		font-size: 28px;
		line-height: 40px;
	}

	.blog-md.md-large .ttr-post-info {
		padding: 10px 0 10px 30px;
	}

	.blog-md.md-large p {
		font-size: 15px;
		line-height: 28px;
	}

	.blog-md.sm-large .media-post li a,
	.blog-md.md-large .media-post li a {
		font-size: 14px;
	}

	.news-area:before {
		font-size: 90px;
		line-height: 90px;
		top: 30px;
	}

	.about-media-bx:before {
		content: none;
	}

	.video-media-bx img {
		width: 100%;
	}

	.counter-style-1 {
		margin-bottom: 40px;
	}

	.news-box .heading-bx.m-t20 {
		margin-top: 0;
	}

	.about-imgbox {
		margin-top: 50px;
	}

	.secondary-inner ul li.extmenu {
		display: none;
	}

	.subscribe-form {
		margin-bottom: 50px;
	}

	.page-banner h1 {
		font-size: 40px;
		line-height: 60px;
	}

	.blog-md .ttr-post-media {
		width: 300px;
	}

	.pagination-bx.m-b0,
	.card.m-b0 {
		margin-bottom: 0;
	}

	.error-404 {
		display: flex;
	}

	h3 {
		font-size: 30px;
		line-height: 40px;
	}

	.service-img-bx {
		display: block;
	}

	.service-img-bx .service-media {
		max-width: 100%;
	}

	.service-img-bx .service-info {
		max-width: 100%;
		padding: 20px 0 0;
	}

	.service-img-bx:nth-child(2n) .service-info {
		padding: 20px 0 0;
	}

	.about-head-bx.heading-bx {
		display: flex;
	}

	.about-head-bx.heading-bx .about-counter {
		width: 50%;
	}

	.about-media-bx.about-video .video-media-bx.split-box {
		margin-top: 100px;
	}

	.about-media-bx.about-video .row.section-sp1 {
		padding-top: 50px;
	}

	.about-img-box {
		position: relative;
		padding: 0 0px 80px 90px;
	}

	.about-video2 .video-media-bx {
		height: 300px;
		margin-bottom: 0;
	}

	.our-story:after {
		content: none;
	}

	.header-2.header-transparent .navbar-toggler span {
		background-color: #232eff;
	}

	.header-2 .navbar-toggler {
		margin: 44px 0 30px 25px;
	}

	.header-2 .sticky-header.active .navbar-toggler {
		margin: 32px 0 32px 25px;
	}

	.header-2 .menu-links,
	.header-2 .sticky-header.active .menu-links {
		padding: 15px;
	}

	/* Home 3 */
	.about-img-box2 {
		width: 100%;
		margin-bottom: 40px;
	}

	.home-bnr-title .title {
		margin-bottom: 20px;
	}

	.home-bnr-title p {
		display: none;
	}
}

@media only screen and (max-width: 767px) {
	.counter-style-1.m-sm-b15 {
		margin-bottom: 15px;
	}

	.about-img-box {
		position: relative;
		padding: 0px 0px 40px 40px;
	}

	.section-sp1 {
		padding-top: 50px;
		padding-bottom: 20px;
	}

	.section-sp2 {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.section-sp3 {
		padding-top: 50px;
		padding-bottom: unset;
	}

	.news-box .heading-bx br {
		display: none;
	}

	.heading-bx h2 {
		font-size: 28px;
		line-height: 40px;
	}

	.testimonial-carousel .owl-nav {
		position: unset;
		margin-top: 30px;
		text-align: left;
	}

	.blog-md.sm-large,
	.blog-md.md-large {
		flex-direction: column;
	}

	.blog-md.md-large .ttr-post-info {
		padding-left: 0;
	}

	.blog-md.sm-large .ttr-post-media,
	.blog-md.md-large .ttr-post-media {
		width: 100%;
		flex: 0 0 100%;
	}

	.post-extra {
		padding-top: 10px;
	}

	.news-area:before {
		top: 0px;
	}

	.page-banner {
		height: 350px;
	}

	.page-banner h1 {
		font-size: 36px;
		line-height: 50px;
	}

	.breadcrumb-row ul li {
		font-size: 14px;
	}

	.breadcrumb-row {
		padding: 5px 15px;
	}

	.recent-news.style-2 {
		margin-bottom: 30px;
	}

	.blog-md .ttr-post-media {
		width: 100%;
	}

	.blog-md.blog-post .ttr-post-info {
		margin-bottom: 0;
	}

	h4 {
		font-size: 24px;
		line-height: 34px;
	}

	blockquote p {
		font-size: 18px;
		line-height: 28px;
	}

	blockquote {
		padding: 10px 20px 10px 25px;
		margin: 20px 0;
	}

	.ttr-post-meta.d-flex {
		display: block !important;
	}

	.ttr-post-meta.d-flex .post-tags {
		margin-bottom: 10px;
	}

	.feature-filters {
		margin-bottom: 30px;
	}



}

.table.table-cards {
	width: 700px;
	max-width: 700px;
}

.checkout-area .text-right {
	text-align: left !important;
	margin-top: 10px;
}

.timeline-view .left:before {
	transform: rotate(180deg);
}

.timeline-view .right:before {
	left: 60px;
}

.service-icon-bx .service-icon a {
	font-size: 70px;
	height: 200px;
	width: 200px;
	line-height: 200px;
}

h5 {
	font-size: 20px;
	line-height: 30px;
}

.case-studies-box .studies-info {
	padding: 20px;
}

.case-studies:before,
.our-partner:before,
.team-area:before {
	font-size: 100px;
	line-height: 120px;
}

.case-studies:before {
	top: 0;
}

.about-media-bx.about-video .video-media-bx.split-box {
	margin-top: 0px;
}

.about-media-bx.about-video .row.section-sp1 {
	padding-top: 30px;
}

.about-counter .counter-bx {
	font-size: 80px;
	line-height: 80px;
}

.about-counter h5 {
	font-size: 15px;
	line-height: 20px;
}

.about-video2 .video-media-bx {
	height: 400px;
}

.dots-style-1 .owl-dots {
	margin-top: 30px;
}

.timeline-box {
	margin-bottom: 40px;
}

.service-icon-bx:first-child {
	padding-top: 10px;
}

.service-icon-bx {
	padding: 50px 0;
}

.service-img-bx {
	margin-bottom: 40px;
}

.about-head-bx {
	margin-bottom: 10px;
}

.timeline-nav ul {
	padding: 0;
	text-align: center;
}

.timeline-nav ul li {
	display: inline-block;
	width: auto;
}

.timeline-nav-box {
	position: sticky;
	top: 50px;
	background-color: #fff;
	z-index: 999;
	padding: 10px 30px;
	margin: 0 15px 30px;
}

.timeline-nav ul li a.active {
	font-size: 24px;
	padding: 5px 0;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.header-2.header-transparent .sticky-header.active .container-fluid>.menu-logo,
.header-2 .container-fluid>.menu-logo {
	padding: 0 10px;
	width: 70px;
	margin-left: -15px;
	height: 70px;
}

.header-2 .menu-logo img {
	max-width: 50px;
}

.header-2 .sticky-header.active .secondary-menu,
.header-2 .secondary-menu {
	padding: 25px 0 20px 0;
}

.header-2 .navbar-toggler,
.header-2 .sticky-header.active .navbar-toggler {
	margin: 28px 0px 28px 15px;
}

.header-2 .header-contact .info-no {
	font-size: 16px;
}

.home-bnr-img {
	width: 100%;
	border-radius: 10px;
	margin-top: 30px;
}

.home-bnr-title {
	padding-right: 0;
}


@media only screen and (max-width: 576px) {
	.footer-top {
		padding-top: 50px;
	}

	.blog-md.md-large {
		margin-bottom: 40px;
	}

	.contact-sidebar .inner {
		padding: 20px;
	}

	.contact-sidebar {
		width: 280px;
	}

	.team-mambers-area {
		margin-bottom: 30px;
	}

	.portfolio-carousel-1.style-1 .portfolio-media img {
		height: calc(100vh - 50px);
	}

	.our-partner:before,
	.team-area:before {
		font-size: 50px;
		line-height: 65px;
	}

	.our-partner-box .partner-logo {
		padding: 15px;
	}

	.our-partner-box {
		padding: 15px;

	}

	.about-year h2 {
		font-size: 60px;
		line-height: 60px;
	}

	.about-imgbox {
		padding-left: 40px;
	}

	.counter-style-1 span,
	.counter-style-1 .counter {
		font-size: 32px;
		line-height: 40px;
	}

	.about-media-bx .heading-bx {
		margin-bottom: 10px;
	}

	.setResizeMargin {
		padding-left: 15px;
	}

	.recent-news-carousel .owl-nav {
		left: 15px;
	}

	.blog-md.blog-post .ttr-post-info {
		margin-bottom: 0;
	}

	.slide-social li:first-child {
		display: none;
	}

	.page-banner h1 {
		font-size: 22px;
		line-height: 30px;
	}

	.banner-sm-title {
		font-size: 18px;
	}

	h4 {
		font-size: 22px;
		line-height: 32px;
	}

	.recent-news.style-2 .info-bx p {
		font-size: 14px;
		line-height: 26px;
	}

	.blog-user .author-profile-pic {
		width: 60px;
		min-width: 60px;
		height: 60px;
	}

	.blog-user .author-profile-content h6 {
		font-size: 20px;
		line-height: 26px;
	}

	.pagination>li>a,
	.pagination>li>span {
		padding: 10px 20px;
		font-size: 12px;
	}

	.event-bx .card-header .event-mamber {
		width: 60px;
		height: 60px;
		left: 20px;
		top: 20px;
	}

	.faq-bx .event-bx .card-header {
		padding: 23px 40px 20px 100px !important;
	}

	h5 {
		font-size: 18px;
		line-height: 26px;
	}

	.error-title {
		font-size: 110px;
		line-height: 110px;
	}

	.error-title span {
		width: 85px;
		height: 85px;
	}

	.error-page h5 {
		font-size: 24px;
		line-height: 34px;
	}

	.my-account {
		width: 100%;
	}

	h3 {
		font-size: 24px;
		line-height: 33px;
	}

	.service-icon-bx {
		display: block;
		max-width: 100%;
		padding: 50px 0;
		text-align: center;
	}

	.service-icon-bx .service-icon {
		margin-bottom: 30px;
		width: 230px;
		margin-left: auto;
		margin-right: auto;
	}

	.service-icon-bx .service-info {
		padding: 0 0 0 0;
		max-width: 100%;
	}

	.service-icon-bx .service-icon:after {
		right: 0;
	}

	.service-icon-bx:nth-child(2n) .service-icon:after {
		right: 0;
		left: auto;
	}

	.service-icon-bx:nth-child(2n) .service-info {
		margin-right: auto;
		margin-left: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.case-studies:before,
	.our-partner:before,
	.team-area:before {
		font-size: 60px;
		line-height: 80px;
		content: none;
	}

	.case-studies:before {
		top: 30px;
	}

	.about-head-bx.heading-bx {
		display: block;
	}

	.head-in {
		padding-left: 0;
	}

	.about-head-bx {
		text-align: left;
	}

	.video-media-bx a {
		width: 80px;
		height: 80px;
		line-height: 80px;
		font-size: 26px;
	}

	.about-video2 .video-media-bx {
		height: 250px;
	}

	.about-video2 .video-media-bx a {
		width: 80px;
		height: 80px;
		line-height: 80px;
		font-size: 24px;
	}

	.client-logo img {
		height: 50px;
	}

	.heading-bx.style2 .title-head {
		font-size: 30px;
		line-height: 45px;
	}

	.heading-bx.style2 p {
		font-size: 16px;
		line-height: 30px;
	}

	.heading-bx.style2.text-white .title-head br,
	.heading-bx.style2 p br {
		display: none;
	}

	.contact-sidebar .title {
		font-size: 20px;
		line-height: 30px;
	}

	.recent-news-carousel {
		margin-bottom: 10px;
	}

	.recent-news-carousel .blog-md:not(.md-large) {
		padding: 15px;
	}

	.footer-top .list-2 ul {
		margin-bottom: 15px;
	}

	.contact-sidebar .logo {
		margin-bottom: 15px;
	}

	.footer_widget ul li a {
		line-height: 24px;
	}

	.footer-top .widget {
		margin-bottom: 30px;
	}

	.footer-top .m-b30 {
		margin-bottom: 0;
	}
}


/* slick-slide */
.slick-slider {
	margin-left: -15px;
	margin-right: -15px;
}

.slick-slider .slider-item {
	padding-left: 15px;
	padding-right: 15px;
}

.slider-sp0 {
	margin-left: 0;
	margin-right: 0;
}

.slider-sp0 .slider-item {
	padding-left: 0;
	padding-right: 0;
}

.slider-sp2 {
	margin-left: -2px;
	margin-right: -2px;
}

.slider-sp2 .slider-item {
	padding-left: 2px;
	padding-right: 2px;
}

.slider-sp7 {
	margin-left: -7px;
	margin-right: -7px;
}

.slider-sp7 .slider-item {
	padding-left: 7px;
	padding-right: 7px;
}

.slider-sp10 {
	margin-left: -10px;
	margin-right: -10px;
}

.slider-sp10 .slider-item {
	padding-left: 10px;
	padding-right: 10px;
}

/* product-gallery */
.product-gallery .item-list {
	display: flex;
	margin: 10px -2px 0;
}

.product-gallery .item-list .nav-item {
	width: 25%;
	padding: 0 2px;
}

.product-gallery .item-list .nav-item .nav-link {
	padding: 0;
}

/* portfolio-carousel-2 */
.portfolio-carousel-2 {
	overflow: hidden;
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.portfolio-carousel-2 .slick-list {
	margin-left: -350px;
	margin-right: -350px;
}

@media only screen and (max-width: 576px) {
	.portfolio-carousel-2 .slick-list {
		margin-left: -250px;
		margin-right: -250px;
	}
}

/* Banner Slider */
.slider-one {
	height: 100vh;
}

.slider-one .slider-item,
.slider-one .slick-list {
	position: relative
}

.slider-one .slider-thumb:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	background-image: url(../images/slider/others/slide-pt.png);
}

.slider-one .slider-content {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 999;
	width: 100%;
	left: 0;
}

.slider-one .slider-content .sub-title {
	font-size: 24px;
	font-weight: 500;
}

.slider-one .slider-content .title {
	font-size: 90px;
	line-height: 110px;
}

.slider-one .slick-list img {
	/* height: 100vh;
	width: 100%; */
	object-fit: cover;
}

.slider-one .slick-arrow::before {
	line-height: 60px;
	font-size: 30px;
}

.slider-one .slick-arrow {
	width: 60px;
	height: 60px;
	border-radius: 0;
	margin: 0 !important;
	top: auto;
	bottom: 0;
	transform: unset;
	line-height: 60px;
}

.slider-one .slick-next {
	background: var(--primary);
	right: 0;
}

.slider-one .slick-prev {
	background: #fff;
	right: 60px;
	left: auto;
}

.slider-one .slick-prev::before {
	color: var(--primary);
}

.slider-one .slick-prev:hover {
	background: #fff;
}

.slider-one .slick-prev:hover:before {
	color: var(--primary);
}

.slider-one .slick-next:hover {
	background: var(--primary);
}

.slider-one .slick-next:hover:before {
	color: #fff;
}

.slider-two .slider-content .title {
	color: #121237;
	font-weight: 600;
	line-height: 75px;
	font-size: 65px;
	font-family: 'Roboto', sans-serif;
}

.slider-two .slider-content .sub-title {
	margin-bottom: 15px;
}

.col-lg-12.text-center>.button-space.margin {
	display: flex;
}

@media only screen and (max-width: 1250px) {
	.slider-one .slider-content .title {
		font-size: 72px;
		line-height: 90px;
	}

	.team-page .team-mamber {
		width: unset;
	}

}

@media only screen and (max-width: 600px) {
	.slider-one .slider-content {
		left: 2%;
	}
}

@media only screen and (max-width: 591px) {
	.col-lg-12.text-center>.button-space.margin {
		display: flex;
		flex-direction: column;
	}

	#responsive-two-button-side1 {
		margin-bottom: 10px;
	}

	.team-page .team-mamber {
		width: unset;
	}

}


.modal-video {
	background-color: rgba(0, 0, 0, 0.9);
}

.map-frame {
	width: 100%;
	height: 500px;
}

#SRLLightbox {
	z-index: 99999;
}

.posi-rl {
	margin-left: calc((100% - 1170px) / 2);
}

.testimonial-right {
	margin-right: -250px;
}

@media only screen and (max-width: 1280px) {
	.posi-rl {
		margin-left: 0;
		padding: 0 30px;
	}

	.testimonial-right {
		margin-right: -15px;
	}
}

@media only screen and (max-width: 767px) {
	.header .top-bar {
		display: none;
	}
}

@media only screen and (max-width: 591px) {
	.heading-bx p {
		font-size: 16px;
		line-height: 26px;
	}

	.posi-rl {
		padding: 0 15px;
	}

	.recent-news-carousel {
		padding: 15px 0px;
	}

	.slide-social {
		display: none;
	}
}

.table>:not(:first-child) {
	border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.link-footer-white {
	color: #fff;

}

.link-footer-unset {
	color: unset;

}

.link-grey {
	color: #505050;

}

.competence_titre {
	color: #505050;
	font-weight: 600;
	display: inline;

}

.competence_titre:hover {
	color: var(--primary);
	cursor: default;
}

.link-hover-primary {
	color: #505050;
	display: inline;

}

.link-hover-primary:hover {
	color: var(--primary);
	cursor: default;

}

.puce-change {
	list-style-type: "►   ";
}

.padding-item-icon {
	text-align: center;
	font-size: 23px;

}

.item-icon-checkout {
	padding-left: 10px;
}

.text-margin {
	margin-right: 10px;
}

/* body > #hubspot-messages-iframe-container > iframe {
	margin-bottom: 84px !important;  
	text-align: left;
} */




@media screen and (max-width: 600px) {

	table {
		border: 0;
	}

	table thead {
		border: none;
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}

	table tr {
		border-bottom: 3px solid #ddd;
		display: block;
		margin-bottom: .625em;
	}

	table td {
		border-bottom: 1px solid #ddd;
		display: block;
		font-size: .8em;
		text-align: right;
	}

	table td::before {
		content: attr(data-label);
		float: left;
		font-weight: bold;
		text-transform: uppercase;
	}

	table td:last-child {
		border-bottom: 0;
	}

}


@media screen and (max-width: 600px) {
	#container-responsive-mobile-partner {
		padding-bottom: 5px;
	}

	#responsive-icon-content {
		margin-top: 30px;
		padding-right: 38px;
	}

	#responsive-icon-feature {

		margin-left: 20px;
	}

	#responsive-icon-feature-box {
		margin-right: 30px;
		margin-top: 10px;
		float: left;
	}

}

/* modal contact rapide */
@media screen and (max-width: 1280px) {
	.modalContainer {
		height: unset !important;
		width: 50% !important;
	}
}

@media screen and (max-width: 600px) {
	.modalContainer {
		width: 90% !important;
	}

	.CloseButton {
		font-size: 40px !important;
	}
}

/* React Markdown */
#root > div > footer > div.footer-top.bt0 > div > div > div.col-6.col-lg-3.col-md-2.col-sm-6 > div > ul > li:nth-child(1) > div > p {
	font-size: 14px;
	padding-right: 44px;
	color: rgba(255, 255, 255, 0.8);
}